/* eslint-disable import/no-unresolved */
import React from 'react';
// ui
import { Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
// components
import { truthty } from 'utils/functions';
import DeleteModal from 'commons/components/DeleteModal';
import ItemList from 'screens/PackagingTypes/components/ItemList';
import Filters from 'screens/PackagingTypes/components/Filters';
import PackagingTypeForm from './PackagingTypeForm';
import TableActionMenu from 'commons/components/TableActionMenu';

const PackagingTypesComponent = (props) => {
  const {
    classes,
    objects,
    filter,
    onFilterChange,
    toggleEdit,
    toggleDelete,
    packagingTypeList,
    anchorEl,
    handleClick,
    handleClose,
    formIsOpen,
    toggleForm,
    changeControls,
    actions,
    formControls,
    edit,
    submit,
    invalidControls,
    deleteIsOpen,
    closeForm,
    submitDelete,
  } = props;
  return (
    <Container maxWidth={false}>
      <Grid container spacing={3} className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h3" component="h2" className={classes.marginTop}>
            Envases
          </Typography>
        </Grid>
        <Filters
          classes={classes}
          filter={filter}
          onFilterChange={onFilterChange}
        />
        <Grid container item>
          <Grid item xs={12} sm={4} md={3}>
            <TableActionMenu
              menuItems={[
                {
                  label: 'Exportar',
                  listItems: [{
                    label: 'Archivo Excel',
                    onClick: actions.exportPackagingTypes,
                    params: [packagingTypeList],
                  }],
                },
              ]}
            />
          </Grid>
        </Grid>
        <ItemList
          classes={classes}
          objects={objects}
          toggleEdit={toggleEdit}
          toggleDelete={toggleDelete}
          packagingTypeList={packagingTypeList}
          anchorEl={anchorEl}
          handleClick={handleClick}
          handleClose={handleClose}
        />
      </Grid>
      <PackagingTypeForm
        isOpen={formIsOpen}
        toggleForm={toggleForm}
        changeControls={changeControls}
        onChange={actions.changeControls}
        controls={formControls}
        edit={edit}
        submit={submit}
        invalidControls={invalidControls}
      />
      <DeleteModal isOpen={deleteIsOpen} toggleForm={closeForm} submit={submitDelete} buttonId="delete-packagingType" />
      <Fab color="primary" aria-label="add" className={classes.fab} onClick={toggleForm}>
        <AddIcon />
      </Fab>
      {
        truthty(anchorEl) && (
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={truthty(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={toggleEdit}>
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              Editar
            </MenuItem>
            <MenuItem onClick={toggleDelete}>
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              Eliminar
            </MenuItem>
          </Menu>
        )}
    </Container>
  );
};

export default PackagingTypesComponent;
