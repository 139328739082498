import React from 'react';

// ui
import { Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
// Icons
import AddIcon from '@material-ui/icons/Add';
import PrintIcon from '@material-ui/icons/Print';
import DeleteIcon from '@material-ui/icons/Delete';
// Project
import { truthty } from 'utils/functions';
// components
import ReturnForm from './ReturnForm';
import ItemList from 'screens/Returns/components/ItemList';
import DeleteModal from 'commons/components/DeleteModal';
import Filters from 'screens/Returns/components/Filters';

const ReturnsComponent = props => (
  <Container maxWidth={false}>
    <Grid container spacing={3} className={props.classes.container}>
      <Grid item xs={12}>
        <Typography variant="h3" component="h2" className={props.classes.marginTop}>
          Devoluciones
        </Typography>
      </Grid>
      <Filters
        classes={props.classes}
        filter={props.filter}
        onFilterChange={props.onFilterChange}
        objects={props.objects}
        clearFilters={props.clearFilters}
        submitFilter={props.submitFilter}
        onCheckboxChange={props.onCheckboxChange}
      />
      {!props.loading && (
        <ItemList
          classes={props.classes}
          objects={props.objects}
          toggleEdit={props.toggleEdit}
          toggleDelete={props.toggleDelete}
          returnList={props.returnList}
          anchorEl={props.anchorEl}
          handleClick={props.handleClick}
          handleClose={props.handleClose}
          returnIdList={props.returnIdList}
          returns={props.returns}
          printFnc={props.printFnc}
	  headerList={props.headerList}
	  isMobileDevice={props.isMobileDevice}
        />
      )}
    </Grid>
    <ReturnForm
      isOpen={props.formIsOpen}
      toggleForm={props.toggleForm}
      changeControls={props.changeControls}
      onChange={props.actions.changeControls}
      controls={props.formControls}
      edit={props.edit}
      submit={props.submit}
      invalidControls={props.invalidControls}
      setDispatchLots={props.setDispatchLots}
      dispatchLots={props.dispatchLots}
      classes={props.classes}
      addToReturnLots={props.addToReturnLots}
      addReturnedLot={props.addReturnedLot}
      deleteReturnedLot={props.deleteReturnedLot}
      changeWeight={props.changeWeight}
      warehouses={props.objects.warehouses}
      fruits={props.objects.fruits}
      varieties={props.objects.varieties}
      products={props.objects.products}
      objects={props.objects}
      packagingTypes={props.objects.packagingTypes}
    />
    <Menu
      anchorEl={props.anchorEl}
      keepMounted
      open={truthty(props.anchorEl)}
      onClose={props.handleClose}
    >
      <MenuItem onClick={props.printFnc}>
        <ListItemIcon>
          <PrintIcon />
        </ListItemIcon>
        Imprimir
      </MenuItem>
      <MenuItem onClick={props.toggleDelete}>
        <ListItemIcon>
          <DeleteIcon />
        </ListItemIcon>
        Eliminar
      </MenuItem>
    </Menu>
    <DeleteModal isOpen={props.deleteIsOpen} toggleForm={props.closeForm} submit={props.submitDelete} buttonId="delete-return" />
    <Fab color="primary" aria-label="add" className={props.classes.fab} onClick={props.toggleForm} >
      <AddIcon />
    </Fab>
  </Container >
);

export default ReturnsComponent;
