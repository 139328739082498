import camelize from 'camelcase-keys-deep';
export default class Account {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }
  
  unit() {
    let response = null;
    if (this.unitId) {
      response = this.env().Unit.findBy('id', this.unitId);
    }
    return response;
  }
}
