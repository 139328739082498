import React from 'react';
// MUI
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// Project
import Header from './Header';
import ResumeTable from './ResumeTable';

export default function Components(props) {
  const {
    classes,
    lot,
    objects,
    tableRows,
    tableHeaders,
    history
  } = props;

  return (
    <Container maxWidth={false}>
      <Grid container spacing={3} className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h3" component="h2" className={classes.marginTop}>
            {`DETALLE PAK ${lot?.id || ''}`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Header
            lot={lot}
            objects={objects}
            history={history}
          />
        </Grid>
        <Grid item xs={12}>
          <ResumeTable
            lot={lot}
            tableRows={tableRows}
            tableHeaders={tableHeaders}
            classes={classes}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
