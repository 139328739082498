/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-param-reassign */
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core';
import { clone } from '../../utils/functions';
import { appActions } from '../../commons/reducer';
import { receiptsActions } from './reducer';
import connectSaga from '../../utils/saga';
import ReceiptsComponent from './components';
import saga from './saga';
import { ReactReduxContext } from '../../config/configureStore';
import { selectState, actionProps } from '../../utils/reduxActions';
import getQueryParams from '../../utils/services/getQueryParams';
import styles from './styles';

const useStyles = makeStyles(styles);

const headerList = [
  { name: 'Lote', width: '7%', mobileOrder: 1 },
  { name: 'Despacho', width: '8%', mobileOrder: 2 },
  { name: 'Fecha ingreso', width: '10%', mobileOrder: 3 },
  { name: 'Proveedor', width: '10%', mobileOrder: 4 },
  { name: 'Producto', width: '10%', mobileOrder: 5 },
  { name: 'Kilos', width: '10%', mobileOrder: 6 },
  { name: 'Cuartel', width: '10%', mobileOrder: 7 },
  { name: 'Cliente interno', width: '10%', mobileOrder: 8 },
  { name: 'Responsable', width: '10%', mobileOrder: 9 },
  { name: 'Cosecha', width: '10%', mobileOrder: 10 },
  { name: '', width: '5%', mobileOrder: 11 },
];



const defaultFilters = {
  fromDate: moment().startOf('month').toISOString(),
  toDate: moment().endOf('month').toISOString(),
  paddockId: '',
  lotNumber: '',
  dispatchOrderNumber: '',
  supplierId: '',
  harvester: '',
  harvestDate: null,
  clientId: '',
  productId: '',
  varietyId: '',
  fruitId: '',
};

const defaultPagination = {
  page: 0,
  rowsPerPage: 10,
  count: 0,
};

const Receipts = (props) => {
  const { actions, loading, deleteIsOpen, pagination, toDeleteId } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  useEffect(() => {
    const params = getQueryParams();
    const newFilters = defaultFilters;

    if (params.lotNumber) {
      newFilters.lotNumber = params.lotNumber.split(',');
    }
    actions.changeFilters(newFilters);
    actions.getStateFromApi(newFilters, defaultPagination);
  }, []);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function toggleForm() {
    actions.toggleForm('formIsOpen');
  }

  function closeForm() {
    actions.toggleForm('deleteIsOpen');
  }

  function submitDelete() {
    actions.deleteReceipt(toDeleteId);
  }

  const classes = useStyles();

  return (
    <ReceiptsComponent
      classes={classes}
      toggleForm={toggleForm}
      actions={actions}
      deleteIsOpen={deleteIsOpen}
      closeForm={closeForm}
      submitDelete={submitDelete}
      anchorEl={anchorEl}
      handleClick={handleClick}
      handleClose={handleClose}
      pagination={pagination}
      loading={loading}
      toggleDeleteLot={actions.toggleDeleteLot}
      defaultFilters={defaultFilters}
      defaultPagination={defaultPagination}
      headerList={headerList}
    />
  );
};

const withSaga = connectSaga({ key: 'sagaReceipts', saga });

const withConnect = connect(
  selectState(
    'receipts.deleteIsOpen',
    'receipts.toDeleteId',
    'receipts.pagination',
    'receipts.loading',
    'app.device',
    'app.account',
  ),
  actionProps(clone(clone.OBJECT, receiptsActions, appActions)),
  null,
  { context: ReactReduxContext },
);

export default compose(withStyles(styles), withRouter, withConnect)(withSaga(Receipts));
