import React, { useState, Fragment } from 'react';
// ui
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuItem from '@material-ui/core/MenuItem';
import CalendarUp from '../../../../commons/assets/icons/CalendarUp.svg';
import CalendarDown from '../../../../commons/assets/icons/CalendarDown.svg';
// utils
import { truthty } from 'utils/functions';
import { getLocalDate } from 'utils/DateFormat';
import moment from 'moment';
// commons
import CollapsedItem from 'commons/components/CollapsedItem';
import { TruncateTextToolTip } from 'commons/components/DataTable/TruncateTextToolTip';
import { AccountCircleOutlined, AssignmentOutlined, CalendarToday } from '@material-ui/icons';
import { Row } from 'commons/components/DataTable/Row';
import { Box } from '@material-ui/core';

const Item = (props) => {
  const { returnItem, classes, objects, toggleDelete, anchorEl, handleClick, handleClose, printFnc } = props;

  const [isOpen, setIsOpen] = useState(null);
  const lots = returnItem.returnProcess().departureLots() || [];

  const getDispathNumber = () => {
    const number = returnItem?.dispatch()?.number || '-';
    return <TruncateTextToolTip text={number} />;
  };

  const getClient = () => {
    const client = returnItem?.dispatch()?.clientName() || '-';
    return <TruncateTextToolTip text={client} />;
  };

  const getDispatchDate = () => {
    const date = getLocalDate(returnItem?.dispatch()?.createdAt);
    return <TruncateTextToolTip text={date.localeDate} />;
  };

  const getReturnDate = () => {
    const date = getLocalDate(returnItem?.returnProcess()?.date).localeDate;
    return <TruncateTextToolTip text={date} />;
  };

  const getDescrption = () => {
    const description = returnItem?.description;
    return <TruncateTextToolTip text={description} />;
  };

  const getOptions = () => {
    return (
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          handleClick(e);
        }}
        data-id={returnItem.id}>
        <MoreVertIcon />
      </IconButton>
    );
  };

  const getArray = () => {
    return [
      {
        value: getDispathNumber(),
        icon: <AssignmentOutlined />,
      },
      {
        value: getClient(),
        icon: <AccountCircleOutlined />,
      },
      {
        value: getDispatchDate(),
        icon: <img src={CalendarUp} alt="calendar" />,
      },
      {
        value: getReturnDate(),
        icon: <img src={CalendarDown} alt="calendar" />,
      },
      {
        value: getDescrption(),
        icon: <AssignmentOutlined />,
      },
      {
        value: <Box className="row-options">{getOptions()}</Box>,
        icon: null,
      },
    ];
  };

  return (
    <>
      <Row
        row={getArray()}
        headerList={props.headerList}
        isMobileDevice={props.isMobileDevice}
        handleOnClick={() => {
          setIsOpen(!isOpen);
        }}
      />
      <CollapsedItem
        lots={lots}
        classes={classes}
        objects={objects}
        itemId={`return-${returnItem.id}`}
        isOpen={isOpen}
        products={undefined}
      />
    </>
  );
};

// return (
//   <>
//     <ListItem key={`return-${returnItem.id}`} button onClick={() => setIsOpen(!isOpen)}>
//       <ListItemAvatar>
//         <Avatar className={classes.blue}>
//           D
//         </Avatar>
//       </ListItemAvatar>
//       <ListItemText
//         primary={(
//           <Grid container justifyContent="space-between">
//             <Grid item>
//               {`Guía de despacho: ${returnItem?.dispatch()?.number}, cliente: ${returnItem?.dispatch()?.clientName()}`}
//             </Grid>
//           </Grid>
//         )}
//         secondary={`Motivo: ${returnItem.description}${returnItem.dispatch() ? ` , fecha de despacho: ${getLocalDate(returnItem.dispatch().createdAt).localeDate}` : '' }, fecha de devolución: ${getLocalDate(returnItem.returnProcess().date).localeDate}`}
//       />
//
//     </ListItem>
//     <CollapsedItem
//       lots={lots}
//       classes={classes}
//       itemId={`return-${returnItem.id}`}
//       isOpen={isOpen}
//       products={undefined}
//     />
//   </>
// );

export default Item;
