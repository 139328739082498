import {
  Chip,
  Grid,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  IconButton,
  Tooltip,
  Box,
} from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { falsy, fNumber, getUnitDetails, truthty } from 'utils/functions';

// icons
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LaunchIcon from '@material-ui/icons/Launch';
import ErrorIcon from '@material-ui/icons/Error';
import ListIcon from '@material-ui/icons/List';
import BoxIcon from '../../../../commons/assets/icons/box.svg';
// import EditIcon from '@material-ui/icons/Edit';
// import DeleteIcon from '@material-ui/icons/Delete';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { getLocalDate } from 'utils/DateFormat';
import { AssignmentOutlined, CalendarTodayOutlined, CategoryOutlined, Label, PersonOutline, ScheduleOutlined } from '@material-ui/icons';
import { Row } from 'commons/components/DataTable/Row';
import { TruncateTextToolTip } from 'commons/components/DataTable/TruncateTextToolTip';

const Item = ({
  classes,
  process,
  objects,
  openMenuHandler,
  closeMenuHandler,
  toggleEdit,
  toggleDelete,
  anchorEl,
  processOnClick,
  headerList,
  isMobileDevice,
  suppliersByProcess
}) => {

  const { unitCode, unitName } = getUnitDetails(objects.accounts);

  const getNumber = () => {
    return (
      <TruncateTextToolTip
        text={`#${process.id || '-'}`}
        style={{ color: truthty(process.closedAt) ? '#e57373' : '#47AA4D', width: 100 }}
      />
    );
  };

  const getSupplier = () => {
    return truthty(suppliersByProcess[process.id]) ? (
      <TruncateTextToolTip
        text={`${suppliersByProcess[process.id]}`}
      />
    ) : (
      '-'
    );
  };

  const getCategory = () => {
    return <TruncateTextToolTip text={process.processCategories()?.name || '-'} />;
  };

  const getProcessType = () => {
    return <TruncateTextToolTip text={process.processType().name || '-'} />;
  };

  const getProduct = () => {
    return truthty(process.entryLots()) ? (
      <TruncateTextToolTip
        text={`${[
          ...new Set(process.entryLots().map((lot) => lot?.fruit()?.name || 'SIN ESPECIE')),
        ].join(', ')}`}
      />
    ) : (
      '-'
    );
  };

  const getVariety = () => {
    return truthty(process.entryLots()) ? (
      <TruncateTextToolTip
        text={`${[
          ...new Set(process.entryLots().map((lot) => lot?.variety()?.name || 'SIN VARIEDAD')),
        ].join(', ')}`}
      />
    ) : (
      '-'
    );
  }

  const getProcessDate = () => {
    return <TruncateTextToolTip text={moment(process.date).format('DD-MM-YYYY')} />;
  };

  const getWorkShiftType = () => {
    return <TruncateTextToolTip text={process.workShiftType().name || '-'} />;
  };

  const getCreatedBy = () => {
    return <TruncateTextToolTip text={process.createdByEmployee()} lines={1} />;
  };

  const getEntry = () => {
    const label = `${fNumber.format(
      process.entryLots().reduce((acum, l) => acum + l.quantity, 0),
    )} ${unitCode}`;
    return (
      <Tooltip title={label} placement="top">
        <Chip
          icon={<ExitToAppIcon />}
          size="small"
          label={label}
          className={classes.blueChip}
          color="primary"
        />
      </Tooltip>
    );
  };

  const getExit = () => {
    const label = `${fNumber.format(
      process.departureLots().reduce((acum, l) => acum + l.quantity, 0),
    )} ${unitCode}`;
    return (
      <Tooltip title={label} placement="top">
        <Chip
          icon={<LaunchIcon />}
          size="small"
          label={label}
          className={classes.greenChip}
          color="primary"
        />
      </Tooltip>
    );
  };

  const getDecrease = () => {
    const label = `${fNumber.format(
      process.entryLots().reduce((acum, l) => acum + l.quantity, 0) -
        process.departureLots().reduce((acum, l) => acum + l.quantity, 0),
    )} ${unitCode}`;
    return (
      <Tooltip title={label} placement="top">
        <Chip
          icon={<ErrorIcon />}
          size="small"
          label={label}
          className={classes.redChip}
          color="primary"
        />
      </Tooltip>
    );
  };

  const getOptions = () => {
    return falsy(process.closedAt) ? (
      <ListItemSecondaryAction>
        <IconButton
          data-id={process.id}
          onClick={(e) => {
            e.stopPropagation();
            openMenuHandler(e);
          }}>
          <MoreVertIcon />
        </IconButton>
      </ListItemSecondaryAction>
    ) : null;
  };

  const getArrayItem = () => [
    {
      value: getNumber(),
      icon: <AssignmentOutlined />,
    },
    { value: getSupplier(), icon: null },
    { value: getCategory(), icon: <ListIcon /> },
    { value: getProcessType(), icon: <CategoryOutlined /> },
    { value: getProduct(), icon: <img src={BoxIcon} className="MuiSvgIcon-root"/> },
    { value: getVariety(), icon: null },
    { value: getProcessDate(), icon: <CalendarTodayOutlined /> },
    { value: getCreatedBy(), icon: <PersonOutline /> },
    { value: getEntry(), icon: null },
    { value: getExit(), icon: null },
    { value: getDecrease(), icon: null },
    { value: <Box className="row-options">{getOptions()}</Box>, icon: null },
  ];

  return (
    <div style={{ cursor: 'pointer' }}>
      <Row
        row={getArrayItem()}
        handleOnClick={processOnClick(process.id)}
        headerList={headerList}
        isMobileDevice={isMobileDevice}
      />
    </div>
  );
  //   <ListItem button>
  //     <Grid container spacing={0} direction="row" alignItems="center" justifyContent="center">
  //       <Grid item xs={6} onClick={processOnClick(process.id)}>
  //         <Grid container spacing={0} direction="row" alignItems="center" justifyContent="center">
  //           <Grid item xs={1}>
  //             <Typography
  //               style={{ color: truthty(process.closedAt) ? '#e57373' : '#47AA4D', width: 100 }}>
  //               #{process.id}
  //             </Typography>
  //           </Grid>
  //           <Grid item xs={11}>
  //             <ListItemText
  //               primary={
  //                 <Grid container justifyContent="space-between">
  //                   <Grid item>
  //                     <Typography style={{ color: truthty(process.closedAt) ? '#e57373' : '' }}>
  //                       {` ${process.processType().name} ${
  //                         truthty(process.entryLots())
  //                           ? `(${[
  //                               ...new Set(
  //                                 process
  //                                   .entryLots()
  //                                   .map((lot) => lot?.fruit()?.name || 'SIN ESPECIE'),
  //                               ),
  //                             ].join(', ')})`
  //                           : ''
  //                       }`}
  //                     </Typography>
  //                   </Grid>
  //                 </Grid>
  //               }
  //               secondary={`${moment(process.date).format(
  //                 'DD-MM-YYYY',
  //               )} | Creado por: ${process.createdByEmployee()} | turno: ${
  //                 process.workShiftType().name
  //               }${
  //                 truthty(process.closedAt)
  //                   ? ` | Finalizado:${getLocalDate(process.closedAt).localeDateTime}`
  //                   : ''
  //               }`}
  //             />
  //           </Grid>
  //         </Grid>
  //       </Grid>
  //       <Grid item xs={6}>
  //         <Grid container spacing={0} direction="row" alignItems="center" justifyContent="center">
  //           <Grid item xs={11} onClick={processOnClick(process.id)}>
  //             <ListItemText>
  //               <Grid item style={{ textAlign: 'end' }}>
  //                 <Chip
  //                   icon={<ExitToAppIcon />}
  //                   size="small"
  //                   label={`Entrada: ${fNumber.format(
  //                     process.entryLots().reduce((acum, l) => acum + l.quantity, 0),
  //                   )} Kg`}
  //                   className={classes.blueChip}
  //                   color="primary"
  //                 />
  //                 <Chip
  //                   icon={<LaunchIcon />}
  //                   size="small"
  //                   label={`Salida: ${fNumber.format(
  //                     process.departureLots().reduce((acum, l) => acum + l.quantity, 0),
  //                   )} Kg`}
  //                   className={classes.greenChip}
  //                   color="primary"
  //                 />
  //                 <Chip
  //                   icon={<ErrorIcon />}
  //                   size="small"
  //                   label={`Merma: ${fNumber.format(
  //                     process.entryLots().reduce((acum, l) => acum + l.quantity, 0) -
  //                       process.departureLots().reduce((acum, l) => acum + l.quantity, 0),
  //                   )} Kg`}
  //                   className={classes.redChip}
  //                   color="primary"
  //                 />
  //               </Grid>
  //             </ListItemText>
  //           </Grid>
  //           <Grid item xs={1}>
  //             {falsy(process.closedAt) && (
  //               <ListItemSecondaryAction>
  //                 <IconButton data-id={process.id} onClick={openMenuHandler}>
  //                   <MoreVertIcon />
  //                 </IconButton>
  //               </ListItemSecondaryAction>
  //             )}
  //           </Grid>
  //         </Grid>
  //       </Grid>
  //     </Grid>
  //   </ListItem>
  // );
};

export default Item;
