import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import connectSaga from 'utils/saga';
import saga from 'screens/ProductsReport/saga';
import { selectState, actionProps } from 'utils/reduxActions';
// ui
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { TableContainer, Button } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import { Container } from '@material-ui/core';
// components
import StyledCardHeader from 'commons/components/StyledCardHeader';
import StyledTableCell from 'commons/components/StyledTableCell';
import MultipleSelector from 'commons/components/MultipleSelector';
import { fCurrency, fNumber, getUnitDetails } from 'utils/functions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { sortArrayBy } from '../../../../utils/arrayFunctions';
import { useDispatch } from 'react-redux';
import * as actions from '../../reducer'
import CostMarginTable from 'screens/ProductsReport/components/CostMarginTable';
// icons

const MarginTable = (props) => {
  const {
    classes,
    productCosts,
    accumulatedCost,
    productCosts2,
    marginData,
    objects: { products, accounts, units },
    productsFiltered,
    onFilterChange,
  } = props;

  const { unitCode, unitName } = getUnitDetails(accounts);

  const dispacher = useDispatch()

  const arrayProductsName = Object.keys(productCosts).map((productId) => ( {
    label: products[productId].name,
    value: parseInt(productId),
  }));

  const handleSelectionChange = (event) => {
    dispacher(actions.productsReportActions.handleSelectionChange(event.value))
  }

  const productsToLoad = productsFiltered.length > 0 ? Object.keys(productCosts).filter((key) => productsFiltered.includes(parseInt(key))) : Object.keys(productCosts) ;

  const productsCostFilter = {};
  for (const key of productsFiltered) {
    if (productCosts.hasOwnProperty(key)) {
      productsCostFilter[key] = productCosts[key];
    }
  }

  const marginDataFilter = {};
  for (const key of productsFiltered) {
    if (marginData.hasOwnProperty(key)) {
      marginDataFilter[key] = marginData[key];
    }
  }

  const [selectedProductId, setSelectedProductId] = useState(null);
  const productsCostFilterToLoad = productsFiltered.length > 0 ? Object.values(productsCostFilter) : Object.values(productCosts);
  const marginDataFilterToLoad = productsFiltered.length > 0 ? Object.values(marginDataFilter) : Object.values(marginData);

  function handleCloseTable() {
    setShowCostMarginTable(false);
  }

  const [showCostMarginTable, setShowCostMarginTable] = useState(false);

  const handleOpen = (productId) => {
    setSelectedProductId(productId);
    setShowCostMarginTable(true);
  }

  const resetSelectedProductId = () => {
    setSelectedProductId(null);
  }

  return (
    <Grid item sm={7} xs={12}>
      <StyledCardHeader className={classes.leftTableTitle} title="Margen" />
      <Card className={classes.leftSubModule} style={{'display':'flex', 'flexDirection': 'column'}}>
        <Grid item container justifyContent="center" alignItems="center" style={{'flex': '0 1 auto'}} >
          <Grid item xs={4} style={{'padding':'10px'}}>
            <MultipleSelector
              id="product-filter"
              selectedValues={productsFiltered}
              options={arrayProductsName}
              onChange={handleSelectionChange}
              label="Producto"
              whereNotOptionLabel="No hay productos"
            />
          </Grid>
          <Grid item xs={8}>
            <Grid container direction='column' style={{'flex': '0 1 auto'}}>
              <Grid item>
                <Grid container direction='row' style={{'flex': '0 1 auto'}}>
                  <Grid item xs={6}>
                    <div className={classes.totalLabel}>
                      Producido{' '}
                      {fNumber.format(
                        productsToLoad.reduce(
                          (acum, d) => acum + marginData[d]?.quantity || 0,
                          0,
                        ),
                      )}{' '}
                      <div className={classes.totalLabelUnit}>[{unitCode}]</div>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className={classes.totalLabel}>
                      Costo:{' '}
                      {fCurrency.format(productsCostFilterToLoad.reduce((acum, pc) => acum + pc, 0))}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction='row' style={{'flex': '0 1 auto'}}>
                  <Grid item xs={6}>
                    <div className={classes.totalLabel}>
                      Venta:{' '}
                      {fCurrency.format(
                        marginDataFilterToLoad.reduce((acum, pId) => pId.value + acum, 0),
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className={classes.totalLabel}>
                      Margen:{' '}
                      {fCurrency.format(
                        productsToLoad.reduce(
                          (acum, pId) => marginData[pId]?.value - productCosts[pId] + acum,
                          0,
                        ),
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
          <TableContainer component={Paper} className={classes.paperContainer} style={{'flex': '1 1 auto'}}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Producto</StyledTableCell>
                  <StyledTableCell align="right">Total [{unitCode}]</StyledTableCell>
                  <StyledTableCell align="right">Costo</StyledTableCell>
                  <StyledTableCell align="right">Venta</StyledTableCell>
                  <StyledTableCell align="right">Margen</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {productsToLoad.map((productId) => (
                  <TableRow key={`margin-product-${productId}`}>
                    <StyledTableCell component="th" scope="lot">
                      {products[productId].name}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {fNumber.format(
                        marginData[productId]?.quantity -
                          Object.values(marginData[productId]?.returns || {}).reduce(
                            (acum, r) => acum + r.quantity,
                            0,
                          ),
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                    <Button 
                      data-cost={productCosts}
                      data-type="productCost"
                      style={{ padding: 0 }}
                      onClick={() => handleOpen(productId)}
                      >
                      {fCurrency.format(productCosts[productId])}
                    </Button>
                    </StyledTableCell>  
                    <StyledTableCell align="right">
                      {fCurrency.format(
                        marginData[productId]?.value -
                          Object.values(marginData[productId]?.returns || {}).reduce(
                            (acum, r) => acum + r.value,
                            0,
                          ),
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {fCurrency.format(
                        marginData[productId]?.value -
                          productCosts[productId] -
                          Object.values(marginData[productId]?.returns || {}).reduce(
                            (acum, r) => acum + r.value,
                            0,
                          ),
                      )}
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
              {showCostMarginTable && selectedProductId !== null && (
                <CostMarginTable 
                  classes={classes}
                  productCosts={productCosts} 
                  accumulatedCost={accumulatedCost}
                  onClose={handleCloseTable} 
                  showCostMarginTable={showCostMarginTable}
                  selectedProductId={selectedProductId} 
                  resetSelectedProductId={resetSelectedProductId}
                />
              )}
            </Table>
          </TableContainer>
      </Card>
    </Grid>
  );
};

const withSaga = connectSaga({ key: 'sagaProductsReport', saga });

const withConnect = connect(
  selectState(
    'productsReports.marginData',
    'productsReports.productCosts',
    'productsReports.accumulatedCost',
    'productsReports.objects',
    'productsReports.productsFiltered'
  ),
  null,
);

export default compose(withConnect)(withSaga(MarginTable));
