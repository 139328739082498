import React from 'react';

// ui
import { Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
// components
import EmployeeForm from './EmployeeForm';
import DeleteModal from 'commons/components/DeleteModal';
import ItemList from 'screens/Employees/components/ItemList';
import Filters from 'screens/Clients/components/Filters';
import TableActionMenu from 'commons/components/TableActionMenu';

const EmployeesComponent = props => (
  <Container maxWidth={false}>
    <Grid container spacing={3} className={props.classes.container}>
      <Grid item xs={12}>
        <Typography variant="h3" component="h2" className={props.classes.marginTop}>
          Empleados
          </Typography>
      </Grid>
      <Filters
        classes={props.classes}
        filter={props.filter}
        onFilterChange={props.onFilterChange}
      />
      <Grid container item>
          <Grid item xs={12} sm={4} md={3}>
            <TableActionMenu
              menuItems={[
                {
                  label: 'Exportar',
                  listItems: [{
                    label: 'Archivo Excel',
                    onClick: props.actions.exportEmployees,
                    params: [props.employeeList],
                  }],
                },
              ]}
            />
          </Grid>
        </Grid>
      <ItemList 
        classes={props.classes}
        objects={props.objects}
        toggleEdit={props.toggleEdit}
        toggleDelete={props.toggleDelete}
        employeeList={props.employeeList}
        anchorEl={props.anchorEl}
        handleClick={props.handleClick}
        handleClose={props.handleClose}
      />
    </Grid>
    <EmployeeForm
      isOpen={props.formIsOpen}
      toggleForm={props.toggleForm}
      changeControls={props.changeControls}
      onChange={props.actions.changeControls}
      controls={props.formControls}
      edit={props.edit}
      submit={props.submit}
      invalidControls={props.invalidControls}
    />
    <DeleteModal isOpen={props.deleteIsOpen} toggleForm={props.closeForm} submit={props.submitDelete} buttonId="delete-employee" />
    <Fab color="primary" aria-label="add" className={props.classes.fab} onClick={props.toggleForm} >
      <AddIcon />
    </Fab>
  </Container >
);

export default EmployeesComponent;
