// @ts-nocheck
import React from 'react';
// material
import { Grid, makeStyles, Card, CardHeader, IconButton, Collapse, CardContent, List, ListItem, ListItemAvatar, Avatar, ListItemText } from '@material-ui/core';
// utils
import { getUnitDetails } from 'utils/functions';
import moment from 'moment';
// components
import { red, blueGrey } from '@material-ui/core/colors';
// icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import WarningIcon from '@material-ui/icons/Warning';

const item = (lot, objects, classes) => {

  const { unitCode, unitName } = getUnitDetails(objects.accounts);
  
  return (
  <ListItem key={`lot-${lot.id}`}>
    <ListItemAvatar>
      <Avatar className={classes.blue}>
        {lot.fruit().name[0].toUpperCase()}
      </Avatar>
    </ListItemAvatar>
    <ListItemText
      primary={`Número: ${lot.id}, Especie: ${lot.fruit().name}, variedad: ${lot.variety().name}, producto: ${lot.product().name}`}
      secondary={`fecha: ${moment(lot.datetime).format('DD-MM-YYYY')}, cantidad: ${lot.quantity} ${unitCode}, envases: ${lot.packagingQuantity} [${lot.packagingType().name}]`}
    />
  </ListItem>);
};

const useStyles = makeStyles((theme) => ({
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  blue: {
    color: theme.palette.getContrastText(blueGrey[500]),
    backgroundColor: blueGrey[500],
    fontFamily: [
      'Roboto'
    ]
  },
}));

const ProcessingProducts = props => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Grid item xs={12}>
      <Card className={classes.root}>
        <CardHeader
          avatar={
            <WarningIcon />
          }
          action={
            <IconButton
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          }
          title="Paks en proceso"
          subheader={`Cantidad: ${props.lots.length}`}
        />
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Grid container item spacing={3} className={classes.container}>
              <Grid item xs={12} className={classes.tableContainer}>
                <List dense>
                  {props.lots
                    .map(lot => item(
                      lot,
                      props.objects,
                      classes
                    ))}
                </List>
              </Grid>
            </Grid>
          </CardContent>
        </Collapse>
      </Card>
    </Grid>
  );
}

export default ProcessingProducts;
