/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
import { createAction, createReducer } from 'utils/reducer';
import moment from 'moment';

// Local constants
const PATH = 'process/';
const DISABLE_ERRORS = `${PATH}DISABLE_ERRORS`;
const TOGGLE_FORM = `${PATH}TOGGLE_FORM`;
const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`;
const TOGGLE_DELETE = `${PATH}TOGGLE_DELETE`;
const TOGGLE_EDIT_PROCESS = `${PATH}TOGGLE_EDIT_PROCESS`;
const SET_INVALID_CONTROLS = `${PATH}SET_INVALID_CONTROLS`;
const STOP_LOADING = `${PATH}STOP_LOADING`;

// Global constants for saga
export const PROCESS = {
  CREATE_PROCESS: `${PATH}CREATE_PROCESS`,
  CREATE_PROCESS_SUCCESS: `${PATH}CREATE_PROCESS_SUCCESS`,
  GET_STATE_FROM_API: `${PATH}GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${PATH}GET_STATE_FROM_API_SUCCESS`,
  DELETE_PROCESS: `${PATH}DELETE_PROCESS`,
  DELETE_PROCESS_SUCCESS: `${PATH}DELETE_PROCESS_SUCCESS`,
  DELETE_PROCESS_FAIL: `${PATH}DELETE_PROCESS_FAIL`,
  EDIT_PROCESS_SUCCESS: `${PATH}EDIT_PROCESS_SUCCESS`,
  EDIT_PROCESS: `${PATH}EDIT_PROCESS`,
  ADD_PROCESS_STAFF: `${PATH}ADD_PROCESS_STAFF`,
  ADD_PROCESS_ITEM_SUCCESS: `${PATH}ADD_PROCESS_ITEM_SUCCESS`,
  DELETE_PROCESS_STAFF: `${PATH}DELETE_PROCESS_STAFF`,
  DELETE_PROCESS_ITEM_SUCCESS: `${PATH}DELETE_PROCESS_ITEM_SUCCESS`,
  ADD_DEPARTURE: `${PATH}ADD_DEPARTURE`,
  DELETE_LOT: `${PATH}DELETE_LOT`,
  ADD_ENTRY: `${PATH}ADD_ENTRY`,
  SET_ENTRY_STATE: `${PATH}SET_ENTRY_STATE`,
  EDIT_LOT_SUCCESS: `${PATH}EDIT_LOT_SUCCESS`,
  DELETE_ENTRY: `${PATH}DELETE_ENTRY`,
  ADD_SUPPLY_LINE: `${PATH}ADD_SUPPLY_LINE`,
  ADD_SUPPLY_LINE_SUCCESS: `${PATH}ADD_SUPPLY_LINE_SUCCESS`,
  DELETE_SUPPLY_LINE: `${PATH}DELETE_SUPPLY_LINE`,
  DELETE_SUPPLY_LINE_SUCCESS: `${PATH}DELETE_SUPPLY_LINE_SUCCESS`,
  CLOSE_PROCESS: `${PATH}CLOSE_PROCESS`,
  CLOSE_PROCESS_SUCCESS: `${PATH}CLOSE_PROCESS_SUCCESS`,
  TOGGLE_CLOSE: `${PATH}TOGGLE_CLOSE`,
  LOAD_PROCESS_RESOURCES: `${PATH}LOAD_PROCESS_RESOURCES`,
  LOAD_PROCESS_RESOURCES_SUCCESS: `${PATH}LOAD_PROCESS_RESOURCES_SUCCESS`,
  ADD_PROCESS_EQUIPMENTS: `${PATH}ADD_PROCESS_EQUIPMENTS`,
  DELETE_PROCESS_EQUIPMENT: `${PATH}DELETE_PROCESS_EQUIPMENT`,
  DELETE_PROCESS_EQUIPMENT_SUCCESS: `${PATH}DELETE_PROCESS_EQUIPMENT_SUCCESS`,
  CREATE_INFO_PAKS: `${PATH}CREATE_INFO_PAKS`,
  CREATE_INFO_PAKS_SUCCESS: `${PATH}CREATE_INFO_PAKS_SUCCESS`,
  INFO_ENTRY_LOTS: `${PATH}INFO_ENTRY_LOTS`,
  INFO_ENTRY_LOTS_SUCCESS: `${PATH}INFO_ENTRY_LOTS_SUCCESS`,
};

// actions
export const processActions = {
  disableErrors: createAction(DISABLE_ERRORS),
  toggleForm: createAction(TOGGLE_FORM, 'modal', 'lot'),
  changeControls: createAction(CHANGE_CONTROLS, 'module', 'controls', 'invalidControls'),
  toggleDelete: createAction(TOGGLE_DELETE, 'actionFnc', 'id'),
  toggleEdit: createAction(TOGGLE_EDIT_PROCESS, 'item'),
  setInvalidControls: createAction(SET_INVALID_CONTROLS, 'module', 'controls'),
  stopLoading: createAction(STOP_LOADING, 'stopLoading'),
  // api
  getStateFromApi: createAction(PROCESS.GET_STATE_FROM_API, 'id'),
  createProcess: createAction(PROCESS.CREATE_PROCESS, 'controls'),
  editProcess: createAction(PROCESS.EDIT_PROCESS, 'id', 'controls'),
  deleteProcess: createAction(PROCESS.DELETE_PROCESS, 'id'),
  addProcessStaff: createAction(PROCESS.ADD_PROCESS_STAFF, 'processId', 'employeeIds'),
  deleteProcessStaff: createAction(PROCESS.DELETE_PROCESS_STAFF, 'id'),
  addDeparture: createAction(PROCESS.ADD_DEPARTURE, 'processId', 'controls', 'totalWeight', 'device'),
  deleteLot: createAction(PROCESS.DELETE_LOT, 'id'),
  addEntry: createAction(PROCESS.ADD_ENTRY, 'lotId', 'processId'),
  setEntryState: createAction(PROCESS.SET_ENTRY_STATE, 'lotId'),
  deleteEntry: createAction(PROCESS.DELETE_ENTRY, 'lotId'),
  addSupplyLine: createAction(PROCESS.ADD_SUPPLY_LINE, 'processId', 'controls', 'inventoryLine'),
  addSupplyLineSuccess: createAction(PROCESS.ADD_SUPPLY_LINE_SUCCESS, 'payload'),
  deleteSupplyLine: createAction(PROCESS.DELETE_SUPPLY_LINE, 'supplyLineId'),
  closeProcess: createAction(PROCESS.CLOSE_PROCESS, 'id', 'closedAt'),
  toggleClose: createAction(PROCESS.TOGGLE_CLOSE),
  loadProcessResources: createAction(PROCESS.LOAD_PROCESS_RESOURCES, 'id'),
  addProcessEquipments: createAction(PROCESS.ADD_PROCESS_EQUIPMENTS, 'processId', 'equipmentIds'),
  deleteProcessEquipment: createAction(PROCESS.DELETE_PROCESS_EQUIPMENT, 'processEquipmentId'),
  infoPaksToPrint: createAction(PROCESS.CREATE_INFO_PAKS, 'accountId', 'lotIds', 'numPaks', 'device'),
  infoEntryLots: createAction(PROCESS.INFO_ENTRY_LOTS, 'lotId', 'processId'),
};

const defaultControls = {
  closeModal: {
    closedAt: new Date().toISOString(),
    isOpen: false,
  },
  staff: {
    employeeIds: [],
  },
  departure: {
    datetime: new Date().toISOString(),
    warehouseId: '',
    fruitId: '',
    productId: '',
    varietyId: '',
    quantity: '',
    packagingQuantity: '',
    packagingTypeId: ''
  },
  entry: {
    lotId: '',
    inputValue: '',
  },
  supplyProducts: {
    supplyProductId: '',
    warehouseId: '',
    quantity: '',
    purchaseId: null,
  },
  processEquipments: {
    equipmentIds: [],
  },
  lotId: '',
  addPaksloading: false,
  accountId: '',
  lotIds: [],
  numPaks: '',
  // process: {},
};

const defaultDelete = {
  actionFnc: '',
  id: '',
  isOpen: false,
};

const defaultInvalidControls = {
  staff: {
    employeeIds: false,
  },
  departure: {
    warehouseId: false,
    productId: false,
    fruitId: false,
    varietyId: false,
    quantity: false,
    packagingQuantity: false,
    packagingTypeId: false
  },
  entry: {
    lotId: false,
  },
  supplyProducts: {
    supplyProductId: false,
    warehouseId: false,
    quantity: false,
  },
  headers: {
    isGroupedInfo: false,
  },
  processEquipments: {
    equipmentIds: false,
  },
};

const initialState = {
  formControls: {
    staff: { ...defaultControls.staff },
    departure: { ...defaultControls.departure },
    entry: { ...defaultControls.entry },
    supplyProducts: { ...defaultControls.supplyProducts },
    closeModal: { ...defaultControls.closeModal },
    headers: { ...defaultControls.headers },
    processEquipments: { ...defaultControls.processEquipments },
    accountId: { ...defaultControls.accountId },
    lotIds: { ...defaultControls.lotIds },
    numPaks: { ...defaultControls.numPaks },
    
  },
  invalidControls: {
    staff: { ...defaultInvalidControls.staff },
    departure: { ...defaultInvalidControls.departure },
    entry: { ...defaultInvalidControls.entry },
    supplyProducts: { ...defaultInvalidControls.supplyProducts },
    processEquipments: { ...defaultInvalidControls.processEquipments },
  },
  formIsOpen: {
    staff: false,
    departure: false,
    supplyProducts: false,
    entry: false,
    processEquipments: false,
  },
  processResources: {
    usedEmployeesIds: [],
    usedEquipmentsIds: [],
  },
  delete: { ...defaultDelete },
  error: false,
  usedLot: {},
  infoLot: {},
  stopLoading: false,
};

const processes = createReducer(initialState, {
  [DISABLE_ERRORS](state) {
    state.error = false;
  },
  [TOGGLE_FORM](state, action) {
    state.formIsOpen[action.modal] = !state.formIsOpen[action.modal];
    if (action.lot){
      state.formControls[action.modal] = {
        datetime: new Date().toISOString(),
        warehouseId: action.lot.warehouseId || '',
        fruitId: action.lot.fruitId || '',
        productId: action.lot.productId || '',
        varietyId: action.lot.varietyId || '',
        quantity: action.lot.quantity || '',
        packagingQuantity: action.lot.packagingQuantity || '',
        packagingTypeId: action.lot.packagingTypeId || '',
        copyQuantity: '',
        lotId: action.lot.id || ''
      };
    }else{
      state.formControls[action.modal] = { ...defaultControls[action.modal] };
    }
    state.invalidControls[action.modal] = { ...defaultInvalidControls[action.modal] };
  },
  [CHANGE_CONTROLS](state, action) {
    state.formControls[action.module] = {
      ...state.formControls[action.module],
      ...action.controls,
    };
    state.invalidControls[action.module] = {
      ...state.invalidControls[action.module],
      ...(action.invalidControls || {}),
    };
  },
  [TOGGLE_DELETE](state, action) {
    state.delete = {
      actionFnc: action.actionFnc,
      isOpen: !state.delete.isOpen,
      id: action.id,
    };
  },
  [SET_INVALID_CONTROLS](state, action) {
    state.invalidControls[action.module] = {
      ...state.invalidControls[action.module],
      ...action.controls,
    };
  },
  [STOP_LOADING](state, action) {
    state.stopLoading = false;
  },
  // api
  [PROCESS.GET_STATE_FROM_API](state) {
    state.lotId = '';
  },
  [PROCESS.GET_STATE_FROM_API_SUCCESS](state, action) {
    const [firstElement] = action.payload.processesIds;
    state.lotId = firstElement;
  },
  [PROCESS.ADD_PROCESS_ITEM_SUCCESS](state, action) {
    if (action.payload.module === 'departure') {
      state.formControls.departure.quantity = '';
    } else {
      state.formIsOpen[action.payload.module] = !state.formIsOpen[action.payload.module];
    }
    state.invalidControls[action.payload.module] = { ...defaultInvalidControls[action.payload.module] };
  },
  [PROCESS.DELETE_PROCESS_ITEM_SUCCESS](state) {
    state.delete = { ...defaultDelete };
  },
  [PROCESS.DELETE_SUPPLY_LINE_SUCCESS](state) {
    state.delete = { ...defaultDelete };
  },
  [PROCESS.EDIT_LOT_SUCCESS](state, action) {
    state.delete = { ...defaultDelete };
    const { module } = action.payload;
    if (module) {
      if (module !== 'entry') state.formIsOpen[module] = !state.formIsOpen[module];
      state.formControls[module] = { ...defaultControls[module] };
      state.invalidControls[module] = { ...defaultInvalidControls[module] };
    }
  },
  [PROCESS.ADD_SUPPLY_LINE_SUCCESS](state) {
    state.formIsOpen.supplyProducts = !state.formIsOpen.supplyProducts;
    state.formControls.supplyProducts = { ...defaultControls.supplyProducts };
    state.invalidControls.supplyProducts = { ...defaultInvalidControls.supplyProducts };
  },
  [PROCESS.TOGGLE_CLOSE](state) {
    state.formControls.closeModal.isOpen = !state.formControls.closeModal.isOpen;
    state.formControls.closeModal.closedAt = new Date().toISOString();
  },
  [PROCESS.CLOSE_PROCESS_SUCCESS](state) {
    state.formControls.closeModal = defaultControls.closeModal;
  },
  [PROCESS.LOAD_PROCESS_RESOURCES_SUCCESS](state, action) {
    state.processResources = action.payload;
  },
  [PROCESS.DELETE_PROCESS_EQUIPMENT_SUCCESS](state) {
    state.delete = { ...defaultDelete };
  },
  [PROCESS.SET_ENTRY_STATE](state, action) {
    state.formControls.entry.lotId = Number(action.lotId)
  },
  [PROCESS.CREATE_INFO_PAKS](state, action) {
    state.formControls = {
      ...state.formControls,
      addPaksloading: true
    }
  },
  [PROCESS.CREATE_INFO_PAKS_SUCCESS](state, action) {
    state.formControls = {
      ...state.formControls,
      accountId: '',
      lotIds: [],
      numPaks: '',
      addPaksloading: false,
    };
    state.invalidControls = { ...defaultInvalidControls };
  },
  [PROCESS.INFO_ENTRY_LOTS](state) {
    state.stopLoading = false;
  },
  [PROCESS.INFO_ENTRY_LOTS_SUCCESS](state, action) {
    state.infoLot = action.payload.infoLot;
    state.usedLot = action.payload.usedLot;
    state.stopLoading = true;
  },
});

export default processes;
