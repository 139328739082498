import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';
import connectSaga from 'utils/saga';
import saga from 'screens/ProductsReport/saga';
import { selectState } from 'utils/reduxActions';
// ui
import { Grid, Card, CardContent, IconButton } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
// components
import StyledCardHeader from 'commons/components/StyledCardHeader';
// utils
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { getUnitDetails } from 'utils/functions';

Chart.register(...registerables, ChartDataLabels);

let renderedProductionChart;

const labelFormatted = (content) => {
  let label = content.split(' ');
  let formatLabel = [];
  for (let i = 0; i < Math.ceil(label.length / 2); i++) {
    formatLabel.push(label[2 * i] + (2 * i + 1 >= label.length ? '' : ' ' + label[2 * i + 1]));
  }
  return formatLabel;
};

const ScrollArrows = () => {
  const steps = 3;

  const selectedData = (position) => {
    renderedProductionChart.options.scales.x.min += position;
    renderedProductionChart.options.scales.x.max += position;
  };

  const scrollChart = (direction) => {
    const maxDisplay = renderedProductionChart.data.labels.length - 1;
    if (direction === 'left') {
      selectedData(-1 * steps);
      if (renderedProductionChart.options.scales.x.min <= 0) {
        renderedProductionChart.options.scales.x.min = 0;
        renderedProductionChart.options.scales.x.max = steps;
      }
    } else {
      selectedData(steps);
      if (renderedProductionChart.options.scales.x.max >= maxDisplay) {
        renderedProductionChart.options.scales.x.min = maxDisplay - steps;
        renderedProductionChart.options.scales.x.max = maxDisplay;
      }
    }
    renderedProductionChart.update();
  };

  return (
    <>
      <IconButton onClick={() => scrollChart('left')}>
        <ChevronLeft />
      </IconButton>
      <IconButton onClick={() => scrollChart('right')}>
        <ChevronRight />
      </IconButton>
    </>
  );
};

function filterArraysByName(processName, data) {
  const filteredDepartures = data.departures.filter((departure, index) => {
    const processType = data.processTypes[index];
    return processType === processName;
  });

  const filteredLosses = data.losses.filter((loss, index) => {
    const processType = data.processTypes[index];
    return processType === processName;
  });

  const filteredProcessTypes = data.processTypes.filter((processType) => {
    return processType === processName;
  });

  return {
    departures: filteredDepartures.slice(0, 1),
    losses: filteredLosses.slice(0, 1),
    processTypes: filteredProcessTypes.slice(0, 1),
  };
}


const ProductionChart = (props) => {
  const { productionGraphData: data, classes, objects } = props;
  const productionState = useSelector((state) => state.productsReports);
  const processFiltered = productionState.processFiltered;
  const filterData = processFiltered.length > 0 ? (filterArraysByName(processFiltered, data)) : (data);

  const { unitCode, unitName } = getUnitDetails(objects.accounts);

  const config = {
    type: 'bar',
    data: {
      labels: filterData.processTypes.map((pt) => labelFormatted(pt)),
      datasets: [
        {
          label: 'Salida',
          data: filterData.departures.map((d) => d.toFixed(2)),
          backgroundColor: '#D6E9C6',
          barPercentage: 0.4,
        },
        {
          label: 'Merma',
          data: filterData.losses.map((l) => l.toFixed(2)),
          backgroundColor: '#EBCCD1',
          barPercentage: 0.4,
        },
      ],
    },
    plugins: [ChartDataLabels],
    options: {
      locale: 'en-DE',
      plugins: {
        datalabels: {
          font: {
            weight: 'bold',
          },
          formatter: function (value, context) {
            return new Intl.NumberFormat('en-DE').format(Number(value)) + `${unitCode}`;
          },
        },
      },
      scales: {
        x: {
          min: 0,
          max: 3,
          ticks: {
            font: {
              size: 10,
            },
          },
          stacked: true,
        },
        y: {
          stacked: true,
          ticks: {
            callback: (value, index, values) => {
              return new Intl.NumberFormat('en-DE').format(value);
            },
          },
        },
      },
      responsive: true,
      maintainAspectRatio: false,
    },
  };

  useEffect(() => {
    const ctx = document.getElementById('chart');
    if (renderedProductionChart) renderedProductionChart.destroy();
    renderedProductionChart = new Chart(ctx, config);
    renderedProductionChart.resize();
  }, [productionState]);


  return (
    <Grid item sm={5} xs={12}>
      <Card className={classes.subModule}>
        <StyledCardHeader title="Rendimiento por proceso" action={<ScrollArrows />} />
        <CardContent className={classes.cardContent}>
          <div style={{ height: '100%', overflow: 'auto' }}>
            <canvas id="chart" style={{ minHeight: 300, position: 'relative' }}></canvas>
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
};


const withSaga = connectSaga({ key: 'sagaProductsReport', saga });

const withConnect = connect(selectState('productsReports.productionGraphData', 'productsReports.processFiltered'), null);

export default compose(withConnect)(withSaga(ProductionChart));
