// @ts-nocheck
import React, { Fragment, useState } from 'react';
// ui
import Grid from '@material-ui/core/Grid';
// material ui
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { TableSortLabel } from '@material-ui/core';
// utils
import { fNumber, truthty, getUnitDetails } from '../../../../utils/functions';
// components
import StyledTableCell from '../../../../commons/components/StyledTableCell';

const row = (il, products, warehouses) => (
  <Fragment key={`inventory-line-loader-${il.id}`}>
    <TableRow key={`inventory-line-${il.id}`}>
      <StyledTableCell component="th" scope="row">
        {products[il.productId].name}
      </StyledTableCell>
      <StyledTableCell>
        {warehouses[il.warehouseId].name}
      </StyledTableCell>
      <StyledTableCell>
        {fNumber.format(il.inputs)}
      </StyledTableCell>
      <StyledTableCell>
        {fNumber.format(il.outputs)}
      </StyledTableCell>
      <StyledTableCell>
        {fNumber.format(il.inputs - il.outputs)}
      </StyledTableCell>
    </TableRow>
  </Fragment>
);

const ProductTable = (props) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(1);

  const {
    purchaseList,
    productLines,
    tableClasses,
    products,
    objects,
    warehouses,
    classes: {
      table,
      container,
      tableContainer,
    },
  } = props;

  const { unitCode, unitName } = getUnitDetails(objects.accounts);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function descendingComparator(a, b) {
    if (orderBy === 1 || orderBy === 2) {
      const type = orderBy === 1 ? 'products' : 'warehouses';
      const id = orderBy === 1 ? 'productId' : 'warehouseId';
      // eslint-disable-next-line react/destructuring-assignment
      if (props[type][b[id]].name < props[type][a[id]].name) {
        return -1;
      }
      // eslint-disable-next-line react/destructuring-assignment
      if (props[type][b[id]].name > props[type][a[id]].name) {
        return 1;
      }
      return 0;
    }
    const valA = a.inputs - a.outputs;
    const valB = b.inputs - b.outputs;
    if (valB < valA) {
      return -1;
    }
    if (valB > valA) {
      return 1;
    }
    return 0;
  }

  function getComparator() {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b)
      : (a, b) => -descendingComparator(a, b);
  }

  return (
    <Grid item xs={12}>
      {!truthty(purchaseList) ? (
        <Grid container item spacing={3} className={container}>
          <Grid item xs={12} className={tableContainer}>
            <TableContainer component={Paper}>
              <Table className={table}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      sortDirection={orderBy === 1 ? order : false}
                    >
                      <TableSortLabel
                        classes={tableClasses}
                        active={orderBy === 1}
                        direction={orderBy === 1 ? order : 'asc'}
                        onClick={() => handleRequestSort(1)}
                      >
                        Producto
                      </TableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell
                      sortDirection={orderBy === 2 ? order : false}
                    >
                      <TableSortLabel
                        classes={tableClasses}
                        active={orderBy === 2}
                        direction={orderBy === 2 ? order : 'asc'}
                        onClick={() => handleRequestSort(2)}
                      >
                        Bodega
                      </TableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell>Entradas [{unitCode}]</StyledTableCell>
                    <StyledTableCell>Salidas [{unitCode}]</StyledTableCell>
                    <StyledTableCell
                      sortDirection={orderBy === 3 ? order : false}
                    >
                      <TableSortLabel
                        classes={tableClasses}
                        active={orderBy === 3}
                        direction={orderBy === 3 ? order : 'asc'}
                        onClick={() => handleRequestSort(3)}
                      >
                        Stock [{unitCode}]
                      </TableSortLabel>
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    Object.values(productLines)
                      .filter((il) => {
                        const {
                          filterProductsControls: {
                            productId,
                            warehouseId,
                          },
                        } = props;

                        const hasProduct = productId === il.productId;
                        const hasWarehouse = warehouseId === il.warehouseId;
                        if (productId && warehouseId) {
                          return hasProduct && hasWarehouse;
                        }
                        if (productId) {
                          return hasProduct;
                        }

                        if (warehouseId) {
                          return hasWarehouse;
                        }

                        return true;
                      })
                      .sort((a, b) => {
                        const orderLines = getComparator()(a, b);
                        if (orderLines !== 0) return orderLines;
                        return a - b;
                      })
                      .map((il) => row(il, products, warehouses))
                    }
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      ) : (
          <Card className={classes.root}>
            <CardContent>
              <Grid container item xs={12} alignItems="center" justifyContent="center">
                <Typography variant="h6" component="h2">
                  No se encontraron productos.
                </Typography>
              </Grid>
            </CardContent>
          </Card>
        )}
    </Grid>
  );
};

export default ProductTable;
