import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import connectSaga from 'utils/saga';
import saga from 'screens/ProductsReport/saga';
import { selectState } from 'utils/reduxActions';
// ui
import { CircularProgress, Container } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ProductionChart from './ProductionChart';
import ProductionTable from './ProductionTable';
import MarginChart from './MarginChart';
import MarginTable from './MarginTable';
import Filters from 'screens/ProductsReport/components/Filters';

const ProcessesComponent = props => {
  const {
    classes,
    filter,
    objects,
    onFilterChange,
    clearFilters,
    filterFnc,
    filteredRls,
    changeControlsProduction,
    loading
  } = props;

  return (
    <Container maxWidth={false}>
      <Grid container spacing={3} className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h3" component="h2" className={classes.marginTop}>
            Reporte de margen y producción
          </Typography>
        </Grid>
        <Filters
          classes={classes}
          filter={filter}
          objects={objects}
          onFilterChange={onFilterChange}
          clearFilters={clearFilters}
          filterFnc={filterFnc}
          filteredRls={filteredRls}
          loading={loading}
        />
        {loading
        ? (
          <Alert severity="info">
            <Grid className={classes.alert}>
              <span style={{ marginRight: 20 }}>El sistema está generando el reporte, por favor espere</span>
              <CircularProgress size={20} thickness={5.0} />
            </Grid>
          </Alert>
        ) : (
          <>
            <MarginTable
              classes={classes}
            />
            <MarginChart classes={classes} />
            <ProductionTable 
              classes={classes}
              objects={objects}
              changeControlsProduction={changeControlsProduction} 
            />
            <ProductionChart 
              classes={classes} 
              objects={objects}
            />
          </>
        )}
      </Grid>
    </Container>
  )
};

const withSaga = connectSaga({ key: 'sagaProductsReport', saga });

const withConnect = connect(selectState('productsReports.loading'), null)

export default compose(withConnect)(withSaga(ProcessesComponent));
