/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import React, { Fragment } from 'react';
// ui
import {
  ListItemText,
  ListItem,
  Collapse,
  List
} from '@material-ui/core';
// utils
import { fCurrency, fNumber, getUnitDetails } from 'utils/functions';

const CollapsedItem = ({
  lots, classes, objects, itemId, isOpen, products
}) => {
  let total = 0;
  const totalValues = lots.reduce((acum, lot) => {
    total += lot.quantity * lot.price;
    acum[lot.productId] = {
      price: (acum[lot.productId]?.price || 0) + lot.quantity * lot.price,
      quantity: (acum[lot.productId]?.quantity || 0) + lot.quantity
    };
    return acum;
  }, {});

  const { unitCode, unitName } = getUnitDetails(objects.accounts);

  return (
    <Collapse in={isOpen} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {products && (
          <>
            <ListItem className={classes.nestedSection}>
              <ListItemText primary={`Total: ${fCurrency.format(total)}`} />
            </ListItem>
            <ListItem className={classes.nestedSection}>
              <ListItemText primary="Total productos" />
            </ListItem>
            {Object.keys(totalValues).map((key) => (
              <ListItem
                key={`${key}-product`}
                className={classes.nested}
              >
                <ListItemText primary={`Totales ${products[key].name}: ${fNumber.format(totalValues[key].quantity)} ${[unitCode]} a ${fCurrency.format(totalValues[key].price)}`} />
              </ListItem>
            ))}
            <ListItem className={classes.nestedSection}>
              <ListItemText primary="Paks" />
            </ListItem>
          </>
        )}
        {lots.map((lot) => (
          <ListItem
            key={`${itemId}-lot-${lot.id}`}
            className={classes.nested}
          >
            <ListItemText
              primary={`N° ${lot.id} | ${lot.product().name}`}
              secondary={`Cantidad: ${lot.quantity.toFixed(2)} ${[unitCode]}, Precio total: ${fCurrency.format(lot.quantity * lot.price)}`}
            />
          </ListItem>
        ))}
      </List>
    </Collapse>
  );
};

export default CollapsedItem;
