/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/no-unresolved */
import React from 'react';
// ui
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
// components
import StyledCardHeader from 'commons/components/StyledCardHeader';
import StyledTableCell from 'commons/components/StyledTableCell';
// utils
import { fNumber, falsy, getUnitDetails, truthty } from 'utils/functions';
// icons
import DeleteIcon from '@material-ui/icons/Delete';
import PrintIcon from '@material-ui/icons/Print';
import IconButtonWithLoader from 'commons/containers/IconButtonWithLoader';

const Departures = (props) => {
  const {
    classes,
    lots,
    objects,
    printAllFnc,
    process,
    toggleDelete,
    printFnc,
    getProductsRows,
    getProductsHeaders,
    departureLots,
    isSmallScreen
  } = props;

  const { unitCode, unitName } = getUnitDetails(objects.accounts);

  return (
    <>
      <Card className={classes.subModule}>
        <StyledCardHeader
          title="Paks de salida"
          subheader={`Total: ${fNumber.format(Object.values(lots)
            .filter((lot) => lot.originProcessId === process.id)
            .reduce((acum, lot) => acum + lot.quantity, 0))
          } [${unitCode}]`}
          action={
            (truthty(lots) && !isSmallScreen) && (
              <Button
                style={{
                  marginTop: '10px',
                }}
                startIcon={<PrintIcon />}
                variant="outlined"
                color="primary"
                onClick={printAllFnc(departureLots.map((lot) => lot.id))}
              >
                IMPRIMIR TODO
              </Button>
            )
          }
        />
        <CardContent className={classes.processCardContent}>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {getProductsHeaders(true)}
                  </TableRow>
                </TableHead>
                <TableBody>
                  { getProductsRows(departureLots, true) }
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default Departures;