// @ts-nocheck
import React, { Fragment } from 'react';
// material
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Paper, List } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
// utils
// icons
import AddIcon from '@material-ui/icons/Add';
// components
import WeightField from '../../../../commons/containers/WeightField';
import { truthty, falsy, getUnitDetails} from '../../../../utils/functions';
import LotListItem from '../LotListItem';

const ReturnedLots = (props) => {
  const {
    controls,
    changeControls,
    invalidControls,
    fruits,
    varieties,
    products,
    objects,
    packagingTypes,
    changeWeight,
    warehouses,
    addReturnedLot,
    classes,
  } = props;

  const { unitCode, unitName } = getUnitDetails(objects.accounts);

  const { quantity, packagingTypeId, packagingQuantity } = controls;
  let totalWeight = 0;
  if (truthty(quantity) && truthty(packagingTypeId) && truthty(packagingQuantity)) {
    totalWeight = quantity - packagingTypes[packagingTypeId].weight * packagingQuantity;
  }

  const disabledAddButton = falsy(controls.packagingQuantity) || controls.packagingQuantity <= 0 || falsy(controls.quantity) || controls.quantity <= 0 || falsy(controls.warehouseId)

  const returnLotForm = (
    <>
      <Grid item sm={6} xs={12}>
        <FormControl fullWidth>
          <InputLabel id="fruit-label">Especie</InputLabel>
          <Select
            labelId="fruit-label"
            fullWidth
            value={controls.fruitId}
            onChange={changeControls}
            error={invalidControls.fruitId}
            disabled
          >
            <MenuItem id="fruitId" value="" data-module="departure">Ninguna</MenuItem>
            {Object.values(fruits).map((fruit) => (
              <MenuItem
                key={`fruit-option-${fruit.id}`}
                id="fruitId"
                value={fruit.id}
                data-module="departure"
              >
                {fruit.name}
              </MenuItem>
            ))}
          </Select>
          {truthty(invalidControls.fruitId) && <FormHelperText error>Debe ingresar una especie</FormHelperText>}
        </FormControl>
      </Grid>
      <Grid item sm={6} xs={12}>
        <FormControl fullWidth>
          <InputLabel id="variety-label">Variedad</InputLabel>
          <Select
            labelId="variety-label"
            fullWidth
            value={controls.varietyId}
            onChange={changeControls}
            error={invalidControls.varietyId}
            disabled
          >
            <MenuItem id="varietyId" value="" data-module="departure">Ninguna</MenuItem>
            {Object.values(varieties).map((variety) => (
              <MenuItem
                key={`variety-option-${variety.id}`}
                id="varietyId"
                value={variety.id}
                data-module="departure"
              >
                {variety.name}
              </MenuItem>
            ))}
          </Select>
          {truthty(invalidControls.varietyId) && <FormHelperText error>Debe ingresar una variedad</FormHelperText>}
        </FormControl>
      </Grid>
      <Grid item sm={6} xs={12}>
        <FormControl fullWidth>
          <InputLabel id="product-label">Producto</InputLabel>
          <Select
            labelId="product-label"
            fullWidth
            value={controls.productId}
            onChange={changeControls}
            error={invalidControls.productId}
            disabled
          >
            <MenuItem id="productId" value="" data-module="departure">Ninguno</MenuItem>
            {Object.values(products).map((product) => (
              <MenuItem
                key={`product-option-${product.id}`}
                id="productId"
                value={product.id}
                data-module="departure"
              >
                {product.name}
              </MenuItem>
            ))}
          </Select>
          {truthty(invalidControls.productId) && <FormHelperText error>Debe ingresar un producto</FormHelperText>}
        </FormControl>
      </Grid>
      <Grid item sm={6} xs={12}>
        <WeightField
          quantity={controls.quantity}
          changeWeight={changeWeight}
          changeControls={changeControls}
          error={invalidControls.quantity}
          helperText={invalidControls.quantity && `Debe ingresar la cantidad de ${unitName}`}
          totalWeight={totalWeight}
          label={unitCode}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <FormControl fullWidth>
          <InputLabel id="container-label">Envase</InputLabel>
          <Select
            labelId="container-label"
            fullWidth
            value={controls.packagingTypeId}
            onChange={changeControls}
            error={invalidControls.packagingTypeId}
            disabled
          >
            <MenuItem id="containerId" value="" data-module="departure">Ningún</MenuItem>
            {Object.values(packagingTypes).map((packagingType) => (
              <MenuItem
                key={`packaging-type-option-${packagingType.id}`}
                id="packagingTypeId"
                value={packagingType.id}
                data-module="departure"
              >
                {packagingType.name}
              </MenuItem>
            ))}
          </Select>
          {truthty(invalidControls.packagingTypeId) && <FormHelperText error>Debe ingresar un tipo de envase</FormHelperText>}
        </FormControl>
      </Grid>
      <Grid item sm={6} xs={12}>
        <TextField
          value={controls.packagingQuantity}
          onChange={changeControls}
          fullWidth
          id="packagingQuantity"
          label="Cantidad de envases"
          inputProps={{ 'data-module': 'departure' }}
          error={invalidControls.packagingQuantity}
          helperText={invalidControls.packagingQuantity && 'Debe ingresar la cantidad de envases'}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <FormControl fullWidth>
          <InputLabel id="warehouse-label">Bodega</InputLabel>
          <Select
            labelId="warehouse-label"
            fullWidth
            value={controls.warehouseId}
            onChange={changeControls}
            error={invalidControls.warehouseId}
          >
            <MenuItem id="warehouseId" value="" data-module="departure">Ninguna</MenuItem>
            {Object.values(warehouses)
              .filter((wh) => !wh.supplyWarehouse)
              .map((warehouse) => (
                <MenuItem
                  key={`warehouse-option-${warehouse.id}`}
                  id="warehouseId"
                  value={warehouse.id}
                  data-module="departure"
                >
                  {warehouse.name}
                </MenuItem>
              ))}
          </Select>
          {truthty(invalidControls.warehouseId) && <FormHelperText error>Debe ingresar una bodega</FormHelperText>}
        </FormControl>
      </Grid>
      <Grid item sm={6} xs={12}>
        <Button
          fullWidth
          size="small"
          startIcon={<AddIcon />}
          onClick={addReturnedLot}
          variant="contained"
          className={classes.filterButton}
          color="primary"
          disabled={disabledAddButton}
        >
          Agregar
        </Button>
      </Grid>
    </>
  );

  return (
    <Grid item container sm={6} xs={12} spacing={1}>
      <Grid item xs={12}>
        <Typography gutterBottom variant="h6" component="h6">
          Paks re-procesados
        </Typography>
      </Grid>
      {returnLotForm}
      <Grid item xs={12}>
        <Paper style={{ padding: 30, margin: 10 }}>
          <List dense>
            {controls.returnedLots.map((l, index) => (
              <LotListItem
                key={`returned-lot-${l.fruitId}-${l.quantity}`}
                lot={l}
                objects={objects}
                index={index}
                product={products[l.productId]}
                classes={classes}
                addToReturnLots={props.addToReturnLots}
                deleteFnc={props.deleteReturnedLot}
              />
            ))}
          </List>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ReturnedLots;
