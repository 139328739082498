import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import connectSaga from 'utils/saga';
import saga from 'screens/ProductsReport/saga';
import { selectState, actionProps } from 'utils/reduxActions';
import { productsReportActions } from 'screens/ProductsReport/reducer';
import { useDispatch } from 'react-redux';
import * as actions from '../../reducer'

// ui
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import { FormControl, InputLabel, Select, MenuItem, Container } from '@material-ui/core';
// components
import StyledCardHeader from 'commons/components/StyledCardHeader';
import StyledTableCell from 'commons/components/StyledTableCell';
import { defaultProcesses } from 'utils/defaultProcesses';
import { fNumber, onChangeFnc, clone, truthty, falsy, getUnitDetails } from 'utils/functions';
// icons

const ProductionTable = (props) => {
  const {
    classes,
    objects,
    objects: { products, processTypes },
    productionTable: {
      productLots = {},
      totalEntries = {},
      totalDeparture = {},
      processesIds = {},
    },
    productionTableControls,
  } = props;

  const { unitCode, unitName } = getUnitDetails(objects.accounts);

  const [tableControls, setTableControls] = useState({ ...productionTableControls });
  const dispacher = useDispatch();


  useEffect(() => {
    if (falsy(tableControls.processTypeId)) {
      if (
        truthty(Object.values(processTypes).filter((p) => !defaultProcesses.includes(p.name))[0])
      ) {
        setTableControls({
          processTypeId: Object.values(processTypes).filter(
            (p) => !defaultProcesses.includes(p.name),
          )[0].id,
        });
      }
    }
  }, []);

  const changeControlsProduction = (event, date) => {
    const processSelected = processTypes[event.target.value].name
    onChangeFnc(setTableControls, event, date);
    dispacher(actions.productsReportActions.handleProcessSelection(processSelected))
  };

  return (
    <Grid item sm={7} xs={12} >
      <StyledCardHeader className={classes.leftTableTitle} title="Rendimiento productivo" />
      <Card className={classes.leftSubModule} style={{'display':'flex', 'flexDirection':'column'}}>
        <Grid container justifyContent="center" style={{'flex': '0 1 auto'}}>
          <Grid item xs={3} style={{'padding': '10px'}} >
            <FormControl fullWidth >
              <InputLabel id="process-type-label">Tipo de proceso</InputLabel>
              <Select
                labelId="process-type-label"
                fullWidth
                value={tableControls.processTypeId}
                onChange={changeControlsProduction}>
                {Object.values(processTypes)
                  .filter((processType) => !defaultProcesses.includes(processType.name))
                  .map((processType) => (
                    <MenuItem
                      key={`process-type-filter-option-${processType.id}`}
                      id="processTypeId"
                      value={processType.id}
                      data-module="productionTableControls">
                      {processType.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <div className={classes.totalLabel}>
              Entradas: {fNumber.format(totalEntries[tableControls.processTypeId])}
              <div className={classes.totalLabelUnit}> [{ unitCode }]</div> 
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={classes.totalLabel}>
              Salidas: {fNumber.format(totalDeparture[tableControls.processTypeId])}
              <div className={classes.totalLabelUnit}> [{ unitCode }]</div> 
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={classes.totalLabel}>
              Mermas:{' '}
              {fNumber.format(
                totalEntries[tableControls.processTypeId] -
                totalDeparture[tableControls.processTypeId],
                )}{' '}
              <div className={classes.totalLabelUnit}> [{ unitCode }]</div> 
            </div>
          </Grid>
        </Grid>
            <TableContainer component={Paper} className={classes.paperContainer} style={{'flex': '1 1 auto'}}>
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Producto</StyledTableCell>
                    <StyledTableCell align="right">Entrada [{ unitCode }]</StyledTableCell>
                    <StyledTableCell align="right">Salida [{ unitCode }]</StyledTableCell>
                    <StyledTableCell align="right">% de salida</StyledTableCell>
                    <StyledTableCell align="right">{ unitCode }/Turno</StyledTableCell> 
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableControls?.processTypeId &&
                    Object.keys(productLots[tableControls.processTypeId]).map((productId) => (
                      <TableRow key={`product-lot-${productId}`}>
                        <StyledTableCell component="th" scope="lot">
                          {products[productId].name}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {fNumber.format(
                            productLots[tableControls.processTypeId][productId].reduce(
                              (acum, lot) => {
                                if (
                                  processesIds[tableControls.processTypeId].includes(
                                    lot.destinyProcessId,
                                  )
                                ) {
                                  return acum + lot.quantity;
                                } else {
                                  return acum;
                                }
                              },
                              0,
                            ),
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {fNumber.format(
                            productLots[tableControls.processTypeId][productId].reduce(
                              (acum, lot) => {
                                if (
                                  processesIds[tableControls.processTypeId].includes(
                                    lot.originProcessId,
                                  )
                                ) {
                                  return acum + lot.quantity;
                                } else {
                                  return acum;
                                }
                              },
                              0,
                            ),
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {fNumber.format(
                            totalDeparture[tableControls.processTypeId] === 0
                              ? 0
                              : Math.round(
                                  ((100 *
                                    productLots[tableControls.processTypeId][productId].reduce(
                                      (acum, lot) => {
                                        if (
                                          processesIds[tableControls.processTypeId].includes(
                                            lot.originProcessId,
                                            )
                                        ) {
                                          return acum + lot.quantity;
                                        } else {
                                          return acum;
                                        }
                                      },
                                      0,
                                    )) /
                                    totalDeparture[tableControls.processTypeId]) *
                                    100,
                                ) / 100,
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {fNumber.format(
                            productLots[tableControls.processTypeId][productId].reduce(
                              (acum, lot) => {
                                if (
                                  processesIds[tableControls.processTypeId].includes(
                                    lot.destinyProcessId,
                                  )
                                ) {
                                  return acum + lot.quantity;
                                } else {
                                  return acum;
                                }
                              },
                              0,
                            ) >
                              productLots[tableControls.processTypeId][productId].reduce(
                                (acum, lot) => {
                                  if (
                                    processesIds[tableControls.processTypeId].includes(
                                      lot.originProcessId,
                                    )
                                  ) {
                                    return acum + lot.quantity;
                                  } else {
                                    return acum;
                                  }
                                },
                                0,
                              )
                              ? productLots[tableControls.processTypeId][productId].reduce(
                                (acum, lot) => {
                                  if (
                                      processesIds[tableControls.processTypeId].includes(
                                        lot.destinyProcessId,
                                        )
                                    ) {
                                      return acum + lot.quantity;
                                    } else {
                                      return acum;
                                    }
                                  },
                                  0,
                                ) / processesIds[tableControls.processTypeId].length
                              : productLots[tableControls.processTypeId][productId].reduce(
                                  (acum, lot) => {
                                    if (
                                      processesIds[tableControls.processTypeId].includes(
                                        lot.originProcessId,
                                      )
                                    ) {
                                      return acum + lot.quantity;
                                    } else {
                                      return acum;
                                    }
                                  },
                                  0,
                                ) / processesIds[tableControls.processTypeId].length,
                          )}
                        </StyledTableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
      </Card>
    </Grid>
  );
};

const withSaga = connectSaga({ key: 'sagaProductsReport', saga });

const withConnect = connect(
  selectState(
    'productsReports.productionTable',
    'productsReports.productionTableControls',
    'productsReports.objects',
    'productsReports.processFiltered'
  ),
  actionProps(clone(clone.OBJECT, { changeControls: productsReportActions.changeControls })),
);

export default compose(withConnect)(withSaga(ProductionTable));
