/* eslint-disable no-param-reassign */
// @ts-nocheck
/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
// Project
import { selectState, actionProps } from 'utils/reduxActions';
import { homeActions } from 'screens/Home/reducer';
import { ReactReduxContext } from 'config/configureStore';
import connectSaga from 'utils/saga';
import { fruitsActions } from 'screens/Fruits/reducer';
import saga from './saga';
// utils
import { clone, falsy } from 'utils/functions';
// components
import FruitsComponent from './components';
// styles
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core';
import styles from './styles';

const useStyles = makeStyles(styles);

const Fruits = props => {
  const [fruitList, setFruitList] = useState([]);
  const [filter, setFilter] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setFruitList(Object.values(props.objects.fruits).filter(item => item.name.toLowerCase().includes(filter.toLowerCase())));
  }, [props.objects.fruits, filter]);

  useEffect(() => {
    props.actions.getStateFromApi();
  }, [props.actions]);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  };

  function handleClose() {
    setAnchorEl(null);
  };

  function toggleForm() {
    props.actions.toggleForm('formIsOpen');
  }

  function closeForm() {
    props.actions.toggleForm('deleteIsOpen');
  }

  function toggleDelete() {
    handleClose();
    props.actions.toggleDelete(anchorEl.dataset.id);
  }

  function toggleEdit() {
    handleClose();
    const item = Object.values(props.objects.fruits)
      .find(item => item.id === Number(anchorEl.dataset.id));
    props.actions.toggleEdit(item);
  }

  function changeControls(event) {
    props.actions.changeControls({ [event.currentTarget.id]: event.target.value }, { [event.currentTarget.id]: false });
  }

  function submit() {
    if (
      falsy(props.formControls.name)
    ) {
      props.actions.setInvalidControls({
        name: falsy(props.formControls.name)
      });
    } else {
      if (props.edit) {
        props.actions.editFruit(props.toEditId, props.formControls);
      } else {
        props.actions.createFruit(props.formControls);
      }
    }
  }

  function submitDelete() {
    props.actions.deleteFruit(props.toDeleteId);
  }

  function onFilterChange(event) {
    setFilter(event.target.value);
  }

  const classes = useStyles();

  return (
    <FruitsComponent
      classes={classes}
      objects={props.objects}
      filter={filter}
      onFilterChange={onFilterChange}
      toggleEdit={toggleEdit}
      toggleDelete={toggleDelete}
      fruitList={fruitList}
      formIsOpen={props.formIsOpen}
      toggleForm={toggleForm}
      changeControls={changeControls}
      actions={props.actions}
      edit={props.edit}
      submit={submit}
      invalidControls={props.invalidControls}
      deleteIsOpen={props.deleteIsOpen}
      closeForm={closeForm}
      submitDelete={submitDelete}
      formControls={props.formControls}
      anchorEl={anchorEl}
      handleClick={handleClick}
      handleClose={handleClose}
    />
  );
};

const withSaga = connectSaga({ key: 'sagaFruits', saga });

const withConnect = connect(
  selectState(
    'fruits.invalidControls',
    'fruits.formControls',
    'fruits.deleteIsOpen',
    'fruits.toDeleteId',
    'fruits.formIsOpen',
    'fruits.toEditId',
    'fruits.edit',
    'app.objects',
  ),
  actionProps(clone(clone.OBJECT, homeActions, fruitsActions)),
  null,
  { context: ReactReduxContext },
);

export default compose(
  withStyles(styles),
  withRouter,
  withConnect,
)(withSaga(Fruits));
