/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-filename-extension */
// @ts-nocheck
import React, { useEffect, useMemo, useState } from 'react';
// material
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import Autocomplete from '@material-ui/lab/Autocomplete';
// utils
// containers
import WeightField from '../../../../commons/containers/WeightField';
import LotListItem from '../../../Returns/components/LotListItem';
import DatePicker from '../../../../commons/components/DatePicker';
import ButtonWithLoader from '../../../../commons/containers/ButtonWithLoader';
import { truthty, falsy, onChangeFnc, clone } from '../../../../utils/functions';
import connectSaga from 'utils/saga';
import { connect } from 'react-redux';
import { actionProps, selectState } from 'utils/reduxActions';
import { receiptsActions } from 'screens/Receipts/reducer';
import { compose } from 'redux';
import saga from '../../saga';
import moment from 'moment';
import { appActions } from 'commons/reducer';
import { getUnitDetails } from 'utils/functions';

const ReceiptForm = (props) => {
  const {
    actions,
    toggleForm,
    device,
    formIsOpen: isOpen,
    edit,
    formControls: stateControls,
    invalidControls,
    objects,
    toEditId,
    classes,
    lastLotNumber,
    addToReturnLots,
    printLotFnc,
    toggleDeleteLot,
  } = props;

  const { unitCode, unitName } = getUnitDetails(objects.accounts);

  const [controls, setControls] = useState({ ...stateControls });

  useEffect(() => {
    setControls({ ...stateControls });
  }, [stateControls]);

  const newLotNumber = controls.lotNumber || Number(lastLotNumber) + 1;

  const settingControls = (changes) => {
    setControls({ ...controls, ...changes });
  };

  const settingPaddockValues = (changes) => {
    const paddock = objects.paddocks[changes.paddockId];
    setControls({
      ...controls,
      paddockId: paddock?.id,
      fruitId: paddock?.fruit().id || '',
      varietyId: paddock?.variety().id || '',
      productId: paddock?.product().id || '',
      ...changes,
    });
  };

  const changeControls = (event, date) => {
    onChangeFnc(settingControls, event, date);
  };

  const changeWeight = (weight) => {
    actions.changeControls({...controls, quantity: weight }, { quantity: false });
  };

  const changePaddock = (event) => {
    onChangeFnc(settingPaddockValues, event)
    // const paddock = objects.paddocks[event.target.value];
    // actions.changeControls({
    //   paddockId: paddock.id,
    //   fruitId: paddock.fruit().id || '',
    //   varietyId: paddock.variety().id || '',
    //   productId: paddock.product().id || '',
    // });
  };

  const varietiesOptions = useMemo(() => {
    return ["", ...Object.values(objects.varieties)
      .filter((variety) => variety.fruitId === controls.fruitId)
      .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))];
  }, [objects.varieties, controls.fruitId]);


  const productsOptions = useMemo(() => {
    return [
      '',
      ...Object.values(objects.products)
               .sort((a, b) => a.name - b.name)
    ]
  }, [objects.products]);

  const productsOptionsFiltered = controls.varietyId
  ? productsOptions.filter((product) => product.varietyId === controls.varietyId)
  : productsOptions.filter((product) => varietiesOptions.find((variety) => product.varietyId === variety.id));

  const productsOptionsWithoutVariety = productsOptions.filter((product) => !product.varietyId);

  productsOptionsWithoutVariety.forEach((product) => productsOptionsFiltered.push(product))


  // const internalClientsOptions = Object.values(objects.clients).filter((client) => client.isInternalClient).sort((a, b) => a.entity().name.toLowerCase() - b.entity().name.toLowerCase());

  const suppliersOptions = useMemo(() => {
    return [
      '',
      ...Object.values(objects.suppliers).sort((a, b) =>
        a.entity().name.toLowerCase() > b.entity().name.toLowerCase() ? 1 : -1
      ),
    ];
  }, [objects.suppliers]);

  const clientsOptions = useMemo(() => {
    return [
      '',
      ...Object.values(objects.clients)
        .filter((client) => client.isInternalClient)
        .sort(
          (a, b) =>
            a.entity().name.toLowerCase() - b.entity().name.toLowerCase()
        ),
    ];
  }, [objects.clients]);

  const packagingTypesOptions = useMemo(() => {
    return [
      '',
      ...Object.values(objects.packagingTypes)
        .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
    ];
  }, [objects.packagingTypes]);

  useEffect(() => {
    if (!controls.receiptId && isOpen) {
      const defaultClient = clientsOptions.find((client) => client.isDefaultClient);
      if (defaultClient) {
        const e = {
          autocomplete: true,
          input: 'clientId',
          value: defaultClient ? defaultClient.id : '',
        };

        changeControls(e);
      }
    }
  }, [isOpen]);

  const fruitsOptions = useMemo(() => {
    return [
      '',
      ...Object.values(objects.fruits).sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)),
    ]
  }, [objects.fruits]);

  const submit = (lotNumber, totalWeight) => {
    if (
      falsy(lotNumber)
      || falsy(controls.supplierId)
      // || falsy(controls.harvester)
      || falsy(controls.dispatchOrderNumber)
      || falsy(controls.paddockId)
      || falsy(controls.price)
    ) {
      actions.setInvalidControls({
        lotNumber: falsy(lotNumber),
        supplierId: falsy(controls.supplierId),
        // harvester: falsy(controls.harvester),
        dispatchOrderNumber: falsy(controls.dispatchOrderNumber),
        paddockId: falsy(controls.paddockId),
        price: falsy(controls.price),
      });
    } else if (edit) {
      actions.editReceipt(toEditId, controls, totalWeight);
    } else {
      actions.changeControls(controls, invalidControls);
      const receipt = { ...controls, lotNumber };
      actions.createReceipt(receipt);
    }
  };

  const addLot = () => {
    const { quantity, packagingTypeId, packagingQuantity, hasPallet } = controls;
    let totalWeight = 0;
    if (truthty(quantity) && truthty(packagingTypeId) && truthty(packagingQuantity)) {
      const palletWeight = (hasPallet && objects.packagingTypes[packagingTypeId].palletWeight) || 0;
      const toDiscountWeight = objects.packagingTypes[packagingTypeId].weight + palletWeight;
      totalWeight = quantity - toDiscountWeight * packagingQuantity;
    }

    if (
      falsy(controls.fruitId)
      || falsy(controls.varietyId)
      || falsy(controls.productId)
      || falsy(controls.packagingTypeId)
      || falsy(controls.quantity)
      || falsy(controls.packagingQuantity)
      || totalWeight <= 0
    ) {
      actions.setInvalidControls({
        fruitId: falsy(controls.fruitId),
        varietyId: falsy(controls.varietyId),
        productId: falsy(controls.productId),
        packagingTypeId: falsy(controls.packagingTypeId),
        quantity: falsy(controls.quantity),
        packagingQuantity: falsy(controls.packagingQuantity),
      });
    } else {
      const toAddLot = {
        receiptId: controls.receiptId,
        fruitId: controls.fruitId,
        varietyId: controls.varietyId,
        productId: controls.productId,
        packagingTypeId: controls.packagingTypeId,
        packagingQuantity: controls.packagingQuantity,
        quantity: totalWeight,
        warehouseId: controls.warehouseId !== '' ? controls.warehouseId : null,
        datetime: moment().toISOString(),
      };
      const toAddLots = [];
      while (toAddLots.length < (controls.multipleLots ? controls.packagingQuantity : 1)) {
        toAddLots.push({
          ...toAddLot,
          packagingQuantity: controls.multipleLots ? 1 : controls.packagingQuantity,
          quantity: controls.multipleLots ? totalWeight / controls.packagingQuantity : totalWeight,
        });
      }
      actions.createReceiptLots(controls.receiptId, toAddLots, device);
    }
  }

  const updateReceipt = (receiptId) => {
    if (
      falsy(lotNumber)
      || falsy(controls.supplierId)
      // || falsy(controls.harvester)
      || falsy(controls.dispatchOrderNumber)
      || falsy(controls.paddockId)
      || falsy(controls.price)
    ) {
      actions.setInvalidControls({
        lotNumber: falsy(lotNumber),
        supplierId: falsy(controls.supplierId),
        // harvester: falsy(controls.harvester),
        dispatchOrderNumber: falsy(controls.dispatchOrderNumber),
        paddockId: falsy(controls.paddockId),
        price: falsy(controls.price),
      });
    } else {
      actions.editReceiptData(receiptId, controls)
    }
  }

  let totalWeight = 0;
  const { quantity, packagingTypeId, packagingQuantity, hasPallet } = controls;

  if (truthty(quantity) && truthty(packagingTypeId) && truthty(packagingQuantity)) {
    const palletWeight = (hasPallet && objects.packagingTypes[packagingTypeId].palletWeight) || 0;
    const toDiscountWeight = objects.packagingTypes[packagingTypeId].weight + palletWeight;
    totalWeight = quantity - toDiscountWeight * packagingQuantity;
  }

  return (
    <Dialog fullWidth onClose={toggleForm} maxWidth="lg" open={isOpen}>
      <DialogTitle>{`${edit ? 'Editar' : 'Crear'} Recepción`}</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography gutterBottom variant="h6" component="h6">
              Datos recepción
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <DatePicker
              inputId="date"
              label="Fecha"
              fullWidth
              value={controls.date}
              onChange={changeControls}
              disabled={Boolean(controls.receiptId)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <DatePicker
              inputId="harvestDate"
              label="Fecha de cosecha"
              fullWidth
              value={controls.harvestDate}
              onChange={changeControls}
              disabled={Boolean(controls.receiptId)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              value={newLotNumber}
              onChange={changeControls}
              fullWidth
              id="lotNumber"
              label="Número de lote"
              error={invalidControls.lotNumber}
              helperText={invalidControls.lotNumber && 'Debe ingresar un número de lote'}
              disabled={Boolean(controls.receiptId)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Autocomplete
              // disabled={Boolean(controls.receiptId)}
              value={controls.supplierId && suppliersOptions.find((s) => s.id === controls.supplierId)}
              id="autocomplete-suppliers-filters-receipts"
              options={suppliersOptions}
              getOptionLabel={(supplier) => supplier && supplier.entity().name}
              renderInput={(params) => <TextField style={{ margin: 0 }} error={truthty(invalidControls.supplierId)} {...params} label="Proveedor" margin="normal" />}
              onChange={(event, newValue) => {
                const e = {
                  autocomplete: true,
                  input: 'supplierId',
                  value: newValue ? newValue.id : '',
                };
                changeControls(e);
              }}
            />
            {truthty(invalidControls.supplierId) && <FormHelperText error>Debe ingresar un proveedor</FormHelperText>}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Autocomplete
              // disabled={Boolean(controls.receiptId)}
              value={controls.clientId && clientsOptions.find((c) => c.id === controls.clientId)}
              id="autocomplete-clients-filters-receipts"
              options={clientsOptions}
              getOptionLabel={(client) => client ? client?.entity()?.name : ''}
              renderInput={(params) => <TextField style={{ margin: 0 }} {...params} label="Cliente interno" margin="normal" />}
              onChange={(event, newValue) => {
                const e = {
                  autocomplete: true,
                  input: 'clientId',
                  value: newValue ? newValue.id : '',
                };
                changeControls(e);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              value={controls.harvester}
              onChange={changeControls}
              fullWidth
              id="harvester"
              label="Responsable"
              error={invalidControls.harvester}
              helperText={invalidControls.harvester && 'Debe ingresar un responsable'}
              // disabled={Boolean(controls.receiptId)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              value={controls.dispatchOrderNumber}
              onChange={changeControls}
              fullWidth
              id="dispatchOrderNumber"
              label="Guía de despacho"
              error={invalidControls.dispatchOrderNumber}
              helperText={invalidControls.dispatchOrderNumber && 'Debe ingresar una guía de despacho'}
              // disabled={Boolean(controls.receiptId)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <InputLabel id="paddock-label">Cuartel</InputLabel>
              <Select
                labelId="paddock-label"
                fullWidth
                value={controls.paddockId}
                onChange={changePaddock}
                error={invalidControls.paddockId}
                // disabled={Boolean(controls.receiptId)}
              >
                <MenuItem id="paddockId" value="">Ninguna</MenuItem>
                {Object.values(objects.paddocks)
                  .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
                  .map((paddock) => <MenuItem key={`paddock-option-${paddock.id}`} id="paddockId" value={paddock.id}>{paddock.name}</MenuItem>)}
              </Select>
              {truthty(invalidControls.paddockId) && <FormHelperText error>Debe ingresar un cuartel</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              value={controls.price}
              onChange={changeControls}
              fullWidth
              id="price"
              label= {`Precio por ${unitCode}`}
              error={invalidControls.price}
              helperText={invalidControls.price && `Debe ingresar la cantidad de ${unitCode}`}
              // disabled={Boolean(controls.receiptId)}
            />
          </Grid>
          {controls.receiptId && (
          <>
          <Grid item container justifyContent="flex-end">
              <Grid item xs={12} sm={6} md={4}>
                <ButtonWithLoader
                  onClick={() => updateReceipt((toEditId || controls.receiptId))}
                  color="primary"
                  variant="outlined"
                  fullWidth
                  autoFocus
                  buttonId="create-edit-receipt-data"
                >
                  Actualizar
                </ButtonWithLoader>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h6" component="h6">
                Datos pak
              </Typography>
            </Grid>
            <Grid container item justifyContent="flex-start">
              <Grid item xs={12} sm={4} md={3}>
                <Tooltip title="Se imprimirá una etiqueta por cada envase">
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={controls.multipleLots}
                        onChange={changeControls}
                        id="multipleLots"
                      />
                    )}
                    label="Separar paks"
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Tooltip title="Se añadirá el peso del palet al destare">
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={controls.hasPallet}
                        onChange={changeControls}
                        id="hasPallet"
                      />
                    )}
                    label="Tiene palet"
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Autocomplete
                value={controls.fruitId && fruitsOptions.find((fruit) => fruit.id === controls.fruitId)}
                id="autocomplete-fruit-receipts"
                options={fruitsOptions}
                getOptionLabel={(fruit) => fruit && fruit.name}
                renderInput={(params) => <TextField style={{ margin: 0 }} {...params} label="Especie" margin="normal" />}
                onChange={(event, newValue) => {
                  const e = {
                    autocomplete: true,
                    input: 'fruitId',
                    value: newValue ? newValue.id : '',
                  };

                  if (falsy(newValue)) {
                    if (truthty(controls.varietyId)) {
                      changeControls({ autocomplete: true, input: 'varietyId', value: '' });
                    }
                    if (truthty(controls.productId)) {
                      changeControls({ autocomplete: true, input: 'productId', value: '' });
                    }
                  }

                  changeControls(e);
                }}
              />
              {truthty(invalidControls.fruitId) && <FormHelperText error>Debe ingresar una especie</FormHelperText>}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Autocomplete
                value={controls.varietyId && varietiesOptions.find((variety) => variety.id === controls.varietyId)}
                id="autocomplete-variety-receipts"
                options={varietiesOptions}
                getOptionLabel={(variety) => variety && variety.name}
                renderInput={(params) => <TextField style={{ margin: 0 }} {...params} label="Variedad" margin="normal" />}
                disabled={falsy(controls.fruitId)}
                onChange={(event, newValue) => {
                  const e = {
                    autocomplete: true,
                    input: 'varietyId',
                    value: newValue ? newValue.id : '',
                  };

                  if (truthty(newValue)) {
                    if (truthty(controls.productId)) {
                      const product = objects.products[controls.productId];
                      if (product.varietyId !== newValue.id) {
                        changeControls({ autocomplete: true, input: 'productId', value: '' });
                      }
                    }
                  } else if (truthty(controls.productId)) {
                    changeControls({ autocomplete: true, input: 'productId', value: '' });
                  }

                  changeControls(e);
                }}
              />
              {truthty(invalidControls.varietyId) && <FormHelperText error>Debe ingresar una variedad</FormHelperText>}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Autocomplete
                value={controls.productId && productsOptionsFiltered.find((product) => product.id === controls.productId)}
                id="autocomplete-product-receipts"
                options={productsOptionsFiltered}
                getOptionLabel={(product) => product && product.name}
                renderInput={(params) => <TextField style={{ margin: 0 }} {...params} label="Producto" margin="normal" />}
                disabled={falsy(controls.fruitId)}
                onChange={(event, newValue) => {
                  const e = {
                    autocomplete: true,
                    input: 'productId',
                    value: newValue ? newValue.id : '',
                  };
                  changeControls(e);
                }}
              />
              {truthty(invalidControls.productId) && <FormHelperText error>Debe ingresar un producto</FormHelperText>}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth>
                <InputLabel id="warehouse-label">Bodega</InputLabel>
                <Select
                  labelId="warehouse-label"
                  fullWidth
                  value={controls.warehouseId}
                  onChange={changeControls}
                  error={invalidControls.warehouseId}
                >
                  <MenuItem id="warehouseId" value="">Ninguno</MenuItem>
                  {Object.values(objects.warehouses)
                    .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
                    .map((warehouse) => <MenuItem key={`warehouse-option-${warehouse.id}`} id="warehouseId" value={warehouse.id}>{warehouse.name}</MenuItem>)}
                </Select>
                {truthty(invalidControls.warehouseId) && <FormHelperText error>Debe ingresar una bodega</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              {/* <FormControl fullWidth>
                <InputLabel id="packagingType-label">Envase</InputLabel>
                <Select
                  labelId="packagingType-label"
                  fullWidth
                  value={controls.packagingTypeId}
                  onChange={changeControls}
                  error={invalidControls.packagingTypeId}
                >
                  <MenuItem id="packagingTypeId" value="">Ninguno</MenuItem>
                  {Object.values(objects.packagingTypes)
                    .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
                    .map((packagingType) => <MenuItem key={`packagingType-option-${packagingType.id}`} id="packagingTypeId" value={packagingType.id}>{packagingType.name}</MenuItem>)}
                </Select>
                {truthty(invalidControls.packagingTypeId) && <FormHelperText error>Debe ingresar un envase</FormHelperText>}
              </FormControl> */}
              <Autocomplete
                value={controls.packagingTypeId && packagingTypesOptions.find((packagingType) => packagingType.id === controls.packagingTypeId)}
                id="autocomplete-packagingType-receipts"
                // options={Object.values(objects.packagingTypes).sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))}
                options={packagingTypesOptions}
                getOptionLabel={(packagingType) => packagingType && packagingType.name}
                renderInput={(params) => <TextField style={{ margin: 0 }} {...params} label="Envase" margin="normal" />}
                onChange={(event, newValue) => {
                  const e = {
                    autocomplete: true,
                    input: 'packagingTypeId',
                    value: newValue ? newValue.id : '',
                  };
                  changeControls(e);
                }}
              />
              {truthty(invalidControls.packagingTypeId) && <FormHelperText error>Debe ingresar un envase</FormHelperText>}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <WeightField
                quantity={controls.quantity}
                changeWeight={changeWeight}
                changeControls={changeControls}
                error={invalidControls.quantity}
                helperText={invalidControls.quantity && `Debe ingresar la cantidad de ${unitCode}`}
                totalWeight={totalWeight}
                label={unitCode}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                value={controls.packagingQuantity}
                onChange={changeControls}
                fullWidth
                id="packagingQuantity"
                label="N° de envases"
                error={invalidControls.packagingQuantity}
                helperText={invalidControls.packagingQuantity && 'Debe ingresar la cantidad de envases'}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6} md={4}>
              <FormControlLabel
                control={(
                  <Switch
                    checked={controls.multipleLots}
                    onChange={changeControls}
                    id="multipleLots"
                  />
                )}
                label="Separar en paks cada envase"
              />
              <FormHelperText>Se imprimirá una etiqueta por cada envase</FormHelperText>
            </Grid> */}
            <Grid item container justifyContent="flex-end">
              <Grid item xs={12} sm={6} md={4}>
                <Button
                  onClick={addLot}
                  color="primary"
                  variant="outlined"
                  fullWidth
                  disabled={controls.addLotsloading}
                >
                  Agregar
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              {truthty(invalidControls.lots) && <FormHelperText error>Debe ingresar al menos un pak</FormHelperText>}
            </Grid>
          </>
          )}
        </Grid>
        {controls.receiptId && (
        <>
          <Grid item container xs={12} spacing={1}>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h6" component="h6">
                Paks recepcionados
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Paper style={{ padding: 30, margin: 10 }}>
                <List dense>
                  {objects.receipts[controls.receiptId].lots()
                    .map((l, index) => (
                      <LotListItem
                        key={`receipt-lot-${l.id}`}
                        lot={l}
                        objects={objects}
                        index={index}
                        product={objects.products[l.productId]}
                        classes={classes}
                        addToReturnLots={addToReturnLots}
                        printFnc={printLotFnc}
                        deleteFnc={() => toggleDeleteLot(l.id)}
                      />
                    ))}
                </List>
              </Paper>
            </Grid>
          </Grid>
        </>
        )}
      </DialogContent>
      <DialogContent />
      <DialogActions>
        <Button onClick={toggleForm} color="primary" variant="outlined">
          Cerrar
        </Button>
        {!controls.receiptId && (
          <ButtonWithLoader onClick={() => submit(newLotNumber, totalWeight)} autoFocus buttonId="create-edit-receipt">
            Crear recepción
          </ButtonWithLoader>
        )}
      </DialogActions>
    </Dialog>
  );
};

const withSaga = connectSaga({ key: 'sagaReceipts', saga });

const withConnect = connect(
  selectState(
    'receipts.editReceiptData',
    'receipts.invalidControls',
    'receipts.lastLotNumber',
    'receipts.formControls',
    'receipts.formIsOpen',
    'receipts.toEditId',
    'receipts.edit',
    'app.objects',
    'app.device',
  ),
  actionProps(clone(clone.OBJECT, receiptsActions, appActions)),
);

export default compose(withConnect)(React.memo(withSaga(ReceiptForm)));
