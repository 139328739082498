import React, { useEffect } from 'react';
// MUI
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
// Untils
import connectScreen from '../../utils/connectScreen';
import { objectToQueryString } from '../../utils/api';
import { selectState } from '../../utils/reduxActions';
import { roundNumber, roundFrontNumber } from '../../utils/functions';
import getQueryParams from '../../utils/services/getQueryParams';
// Project
import styles from './styles';
import saga from './saga';
import { lotResumeActions } from './reducer';
import Components from './components';

const LotResume = (props) => {
  const {
    actions,
    objects,
    lotResume,
    classes,
    lotResume: {
      resumeInfo,
      controls,
      loading
    },
    history
  } = props;

  const lot = objects.lots[controls.lotId] || null;

  useEffect(() => {
    const params = getQueryParams();
    actions.changeControls(params);
    actions.getStateFromApi(params);
  }, []);

  const tableHeaders = [
    { label: 'LOTE', tooltip: 'Número de lote o recepción' },
    { label: 'PRODUCTOR', tooltip: 'Nombre del productor de la recepcion' },
    { label: 'CLIENTE', tooltip: 'Nombre del productor cliente interno de la recepcion' },
    { label: 'PORCENTAJE', tooltip: 'Porcentaje del pak que pertenece al lote' },
    { label: 'CANTIDAD', tooltip: 'Cantidad en relacion al porcentaje del lote al que pertenece' }
  ];

  const tableRows = resumeInfo.slice() // Slice for strict mode
    .sort((a, b) => b.weight - a.weight)
    .reduce((acum, resume) => {
      const { lotNumber } = resume;
      const supplier = resume.supplierId ? objects.suppliers[resume.supplierId] : null;
      const client = resume.clientId ? objects.clients[resume.clientId] : null;

      acum.push([
        { label: lotNumber, onClick: () => history.push(`receipts?${objectToQueryString({ lotNumber })}`) },
        { label: supplier?.entity()?.name || '' },
        { label: client?.entity()?.name || '' },
        { label: roundNumber(resume.percentage, 2), tooltip: resume.percentage },
        { label: roundNumber(resume.weight, 2), tooltip: resume.weight },
      ]);

      return acum;
    }, []);

  return (
    <>
      {
        loading ? (
          <Grid item container justifyContent="center" alignContent="center">
            <CircularProgress />
          </Grid>
        ) : (
          <Components
            lot={lot}
            classes={classes}
            objects={objects}
            tableHeaders={tableHeaders}
            tableRows={tableRows}
            history={history}
            {...lotResume}
          />
        )
      }
    </>
  );
};

export default connectScreen(
  'LotResume',
  LotResume,
  saga,
  lotResumeActions,
  selectState('lotResume', 'app.objects'),
  styles,
);
