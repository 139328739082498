import React from 'react';
// ui
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import ToolTip from '@material-ui/core/Tooltip';
// utils
import { Checkbox, IconButton } from '@material-ui/core';
// icons
import DeleteIcon from '@material-ui/icons/Delete';
import PrintIcon from '@material-ui/icons/Print';
import RestoreIcon from '@material-ui/icons/Restore';
import { getUnitDetails } from 'utils/functions';

const LotListItem = (props) => {
  const {
    classes,
    product,
    lot,
    objects,
    index,
    checkbox,
    addToReturnLots,
    checked,
    deleteFnc,
    printFnc,
    toDeleteIds = [],
    restoreFnc,
  } = props;

  const { unitCode, unitName } = getUnitDetails(objects.accounts);

  const id = lot.id || index;

  return (
    <ListItem dense key={`dispatch-lot-${id}`}>
      <ListItemAvatar>
        <Avatar className={classes.blue}>
          {product.name[0].toUpperCase()}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={`${lot.id ? `Pak N°: ${lot.id}, ` : ''}Producto: ${product.name}`}
        secondary={`Cantidad: ${lot.quantity} [${unitCode}]`}
      />
      {checkbox && (
        <ListItemSecondaryAction>
          <Checkbox
            edge="end"
            onChange={addToReturnLots}
            checked={checked}
            // @ts-ignore
            inputProps={{ 'data-id': id }}
          />
        </ListItemSecondaryAction>
      )}
      <ListItemSecondaryAction>
        {
          printFnc && (
            <ToolTip title="Imprimir" arrow>
              <IconButton
                onClick={printFnc}
                data-id={lot.id || index}
                size="medium"
              >
                <PrintIcon fontSize="medium" />
              </IconButton>
            </ToolTip>
          )
        }
        { deleteFnc && (
          <ToolTip title="Eliminar" arrow>
            <IconButton
              onClick={deleteFnc}
              data-id={lot.id || index}
              size="medium"
            >
              <DeleteIcon fontSize="medium" />
            </IconButton>
          </ToolTip>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  )
};

export default LotListItem;
