/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from 'react';
// ui
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Menu from '@material-ui/core/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
// icons
import InfoIcon from '@material-ui/icons/Info';
import OfflinePinIcon from '@material-ui/icons/OfflinePin';
import PrintIcon from '@material-ui/icons/Print';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
// utils
import moment from 'moment';
import CustomPagination from '../../../../commons/components/CustomPagination';
import SkeletonList from '../../../../commons/components/SkeletonList';
import { clone, fNumber, getUnitDetails, truthty } from '../../../../utils/functions';
import { selectState, actionProps } from '../../../../utils/reduxActions';
import { receiptsActions } from '../../reducer';
import connectSaga from '../../../../utils/saga';
import saga from '../../saga';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { appActions } from 'commons/reducer';
import { objectToQueryString } from 'utils/api';
import { getLocalDate } from 'utils/DateFormat';
import { withRouter } from 'react-router-dom';
import { Header } from 'commons/components/DataTable/Header';
import { useIsMobileDevice } from 'utils/hooks/isMobileDevice';
import { Row } from 'commons/components/DataTable/Row';
import { AccountCircleOutlined, AssignmentOutlined, CalendarTodayOutlined, HouseOutlined, LayersOutlined, LocalShippingOutlined } from '@material-ui/icons';
import { Box, Tooltip } from '@material-ui/core';

const ItemMenu = (receipt, classes, handleClick) => (
  <ListItem key={`receipt-${receipt.id}`}>
    <ListItemAvatar>
      <Avatar className={classes.blue}>{receipt.supplier().entity().name[0].toUpperCase()}</Avatar>
    </ListItemAvatar>
    <ListItemText
      primary={
        <Grid container justifyContent="space-between">
          <Grid item>
            {`Guía de despacho: ${receipt.dispatchOrderNumber}, Número de lote: ${
              receipt.receiptLot().number
            }, fecha de ingreso: ${moment(receipt.process().date).format('DD-MM-YYYY')}`}
          </Grid>
          <Grid item></Grid>
        </Grid>
      }
      secondary={`Cuartel: ${receipt.receiptLot().paddock().name}, proveedor: ${
        receipt.supplier().entity().name
      }, cosechero: ${receipt.harvester}, fecha de cosecha: ${moment(receipt.harvestDate).format(
        'DD-MM-YYYY',
      )}`}
    />
    <ListItemSecondaryAction>
      <IconButton onClick={handleClick} data-id={receipt.id}>
        <MoreVertIcon />
      </IconButton>
    </ListItemSecondaryAction>
  </ListItem>
);

const ItemList = (props) => {
  const {
    account,
    actions,
    classes,
    device,
    receiptIdList,
    loading,
    pagination,
    objects,
    inputSearch,
    anchorEl,
    handleClose,
    history,
    handleClick,
    headerList,
  } = props;
  
  const { unitCode, unitName } = getUnitDetails(objects.accounts);

  const [receipts, setReceipts] = useState([]);
  const isMobileDevice = useIsMobileDevice();
  console.log('isMobileDevice', isMobileDevice);

  useEffect(() => {
    setReceipts(
      Object.values(objects.receipts)
        .filter((receipt) => receiptIdList?.includes(receipt.id))
        .sort((a, b) => b.id - a.id),
    );
  }, [objects.receipts, receiptIdList]);

  const handleChangeRowsPerPage = (e) => {
    actions.paginationControls(inputSearch, {
      ...pagination,
      rowsPerPage: e.target.value,
    });
  };

  const handleChangePage = (e, val) => {
    actions.paginationControls(inputSearch, { ...pagination, page: val });
  };

  const toggleRepeat = () => {
    handleClose();
    const item = Object.values(objects.receipts).find(
      (item) => item.id === Number(anchorEl.dataset.id),
    );
    actions.toggleEdit(item, true);
  };

  const toggleEdit = () => {
    handleClose();
    const item = Object.values(objects.receipts).find(
      (item) => item.id === Number(anchorEl.dataset.id),
    );
    actions.toggleEdit(item);
  };

  const printFnc = () => {
    const receipt = Object.values(objects.receipts).find(
      (item) => item.id === Number(anchorEl.dataset.id),
    );
    const labelData = {
      lotIds: receipt.lots().map((l) => l.id),
      accountId: account?.id,
    };
    actions.printReceptionLabel(device, labelData)
  };

  const toggleTaceability = () => {
    handleClose();
    const receipt = objects.receipts[anchorEl.dataset.id];
    const query = objectToQueryString({
      lotIds: receipt.lots().map((lot) => lot.id),
    });

    history.push(`lotTraceability?${query}`);
  };

  function toggleDelete() {
    handleClose();
    actions.toggleDelete(anchorEl.dataset.id);
  }

  const getOptions = (receipt) => {
    return (
      <IconButton onClick={handleClick} data-id={receipt.id}>
        <MoreVertIcon />
      </IconButton>
    );
  };

  const getChipProduct = (receipt) => {
    if (receipt.process().departureLots()[0]) {
      const text = `${receipt.process().departureLots()[0].fruit().name} ${
        receipt.process().departureLots()[0].variety().name
      }`;
      return (
        <Tooltip title={text} placement="top">
          <div>
            <Chip
              icon={<InfoIcon />}
              size="small"
              label={text}
              className={classes.blueChip}
              color="primary"
            />
          </div>
        </Tooltip>
      );
    }
    return null;
  };

  const getChipWeight = (receipt) => {
    const text = `${fNumber.format(
      receipt
        .process()
        .departureLots()
        .reduce((acum, l) => acum + l.quantity, 0),
    )} ${unitCode}`;
    return (
      <Tooltip title={text} placement="top">
        <Chip
          icon={<OfflinePinIcon />}
          size="small"
          label={text}
          className={classes.greenChip}
          color="primary"
        />
      </Tooltip>
    );
  };

  const getArrayReceipt = (receipt) => [
    { value: receipt.receiptLot().number || '-', icon: <LayersOutlined /> },
    { value: receipt.dispatchOrderNumber || '-', icon: <AssignmentOutlined /> },
    { value: <>
              {getLocalDate(receipt.process().date).localeDate}
              <br />
              {getLocalDate(receipt.process().date).localeTime}
              </>
      , icon: <CalendarTodayOutlined /> },
    { value: receipt.supplier().entity().name || '-', icon: <LocalShippingOutlined /> },
    { value: getChipProduct(receipt) },
    { value: getChipWeight(receipt) },
    { value: receipt.receiptLot().paddock().name || '-', icon: <HouseOutlined /> },
    { value: receipt.client()?.entity()?.name || '-', icon: <AccountCircleOutlined /> },
    { value: receipt.harvester || '-', icon: <AccountCircleOutlined /> },
    { value: moment(receipt.harvestDate).format('DD-MM-YYYY'), icon: <CalendarTodayOutlined /> },
    { value: <Box className="row-options">{getOptions(receipt)}</Box> },
  ];

  return (
    <Grid item xs={12}>
      <Card className={classes.root}>
        <CardContent>
          {truthty(receipts) ? (
            <Grid container spacing={3} className={classes.container}>
              <Grid item xs={12}>
                {loading ? (
                  <SkeletonList times={pagination.rowsPerPage} />
                ) : (
                  <>
                    <Header headerList={headerList} isMobileDevice={isMobileDevice} />
                    {receipts.map((receipt) => (
                      <Row
                        row={getArrayReceipt(receipt)}
                        headerList={headerList}
                        isMobileDevice={isMobileDevice}
                        handleOnClick={() => {}}
                      />
                    ))}
                    {/*<List>
                      {receipts.map((receipt) => ItemMenu(receipt, classes, handleClick))}
                    </List>*/}
                  </>
                )}
              </Grid>
              <CustomPagination
                rowsPerPage={pagination.rowsPerPage}
                page={pagination.page}
                count={pagination.count}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleChangePage={handleChangePage}
                loading={loading}
              />
              <Menu anchorEl={anchorEl} keepMounted open={truthty(anchorEl)} onClose={handleClose}>
                <MenuItem onClick={printFnc}>
                  <ListItemIcon>
                    <PrintIcon />
                  </ListItemIcon>
                  Imprimir
                </MenuItem>
                <MenuItem onClick={toggleTaceability}>
                  <ListItemIcon>
                    <TrendingUpIcon />
                  </ListItemIcon>
                  Trazabilidad
                </MenuItem>
                <MenuItem onClick={toggleEdit}>
                  <ListItemIcon>
                    <EditIcon />
                  </ListItemIcon>
                  Editar
                </MenuItem>
                <MenuItem onClick={toggleDelete}>
                  <ListItemIcon>
                    <DeleteIcon />
                  </ListItemIcon>
                  Eliminar
                </MenuItem>
              </Menu>
            </Grid>
          ) : (
            <Grid container item xs={12} alignItems="center" justifyContent="center">
              <Typography variant="h6" component="h2">
                No se han ingresado Recepciones
              </Typography>
            </Grid>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

const withSaga = connectSaga({ key: 'sagaReceipts', saga });

const withConnect = connect(
  selectState(
    'receipts.receiptIdList',
    'receipts.pagination',
    'receipts.loading',
    'receipts.inputSearch',
    'app.objects',
    'app.device',
    'app.account',
  ),
  actionProps(
    clone(clone.OBJECT, {
      paginationControls: receiptsActions.paginationControls,
      toggleEdit: receiptsActions.toggleEdit,
      toggleDelete: receiptsActions.toggleDelete,
      printLabel: appActions.printLabel,
      printReceptionLabel: appActions.printReceptionLabel,
    }),
  ),
);

export default compose(withRouter, withConnect)(withSaga(ItemList));
