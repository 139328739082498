import React from 'react';
// ui
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
// utils
import { truthty } from 'utils/functions';
// components
import Item from 'screens/Returns/components/ItemList/Item';
import {Header} from 'commons/components/DataTable/Header';

const ItemList = props => (
  <Grid item xs={12}>
    <Card className={props.classes.root}>
      <CardContent>
        {truthty(props.returns) ? (
          <>
	  <Header headerList={props.headerList} isMobileDevice={props.isMobileDevice} />
          <Grid container spacing={3} className={props.classes.container}>
            <Grid item xs={12}>
              <List>
                {props.returns
                  .map(returnItem => (
                    <Item
                      key={`return-${returnItem.id}`}
                      returnItem={returnItem}
                      classes={props.classes}
                      objects={props.objects}
                      toggleEdit={props.toggleEdit}
                      toggleDelete={props.toggleDelete}
                      anchorEl={props.anchorEl}
                      handleClick={props.handleClick}
                      handleClose={props.handleClose}
		      headerList={props.headerList}
			  isMobileDevice={props.isMobileDevice}
                    />))}
              </List>
            </Grid>
          </Grid>
		</>
        ) : (
            <Grid container item xs={12} alignItems="center" justifyContent="center">
              <Typography variant="h6" component="h2">
                No se han ingresado devoluciones
              </Typography>
            </Grid>
          )}
      </CardContent>
    </Card>
  </Grid>
);

export default ItemList;
